import {
  Alert,
  Button,
  Col,
  FormFeedback,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';

import { useEffect, useState } from 'react';
import api from '../../apis';
import { useSelector } from 'react-redux';

const FuturesBot = () => {
  const futuresLeverageOptions = [5, 10, 15];

  const [futuresActive, setFuturesActive] = useState(false);
  const [pnl, setPnl] = useState(0);

  const [futuresDollarAmount, setFuturesDollarAmount] = useState(0);
  const [futuresDollarAmountInvalid, setFuturesDollarAmountInvalid] =
    useState(false);
  const [leverage, setLeverage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const { authToken } = useSelector((state) => state.auth);

  useEffect(() => {
    setPageLoading(true);
    //get futures bot info
    api
      .getUser(authToken)
      .then((res) => {
        console.log(res);
        if (res.data.user.futuresIsActive) {
          setFuturesActive(true);
        }
        setFuturesDollarAmount(res.data.user.futuresDollarAmount);
        setLeverage(res.data.user.leverage);
      })
      .catch((err) => {
        console.log(err);
      });

    //get pnl info.
    api
      .getPnl(authToken)
      .then((response) => {
        setPnl(response.data.pnl);
        setPageLoading(false);
      })
      .catch((err) => {
        setPageLoading(false);
      });
  }, [authToken]);

  const startBot = async () => {
    setLoading(true);
    if (futuresDollarAmount >= 200) {
      api
        .updateFuturesBot(
          {
            leverage,
            futuresDollarAmount,
            futuresIsActive: true,
          },
          authToken,
        )
        .then((response) => {
          setLoading(false);
          window.location.reload();
        })
        .catch((err) => {
          setLoading(false);
        });
    } else {
      setFuturesDollarAmountInvalid(true);
      setLoading(false);
    }
  };

  const stopBot = async () => {
    setLoading(true);
    api
      .updateFuturesBot(
        {
          leverage,
          futuresDollarAmount,
          futuresIsActive: false,
        },
        authToken,
      )
      .then((response) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  let randombg = ['#BAF7FF', '#CFF4CC', '#E2CBFF'];

  return (
    <>
      {pageLoading ? (
        <Row className="justify-center full-height align-items-center">
          <Spinner />
        </Row>
      ) : (
        <Row className="flex m-0">
          <Col
            xs="12"

            className="flex justify-center  mb-5 font-montserrat p-0 w-full"
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <div className="bgcoverFutures"></div>
            {/* <h1 className=" font-montserrat flex w-full justify-start pl-10 items-center text-[30px]"> */}{" "}
            <div className="w-full h-0 font-montserrat text-[30px] p-3 text-white relative -top-[100px] flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 73 73"
                className="mr-2"
                fill="none"
              >
                <path
                  d="M18.2501 6.08325H63.8751V57.7916C63.8751 59.4049 63.2342 60.9522 62.0933 62.0931C60.9523 63.2341 59.405 63.8749 57.7917 63.8749H12.1667L18.2501 57.7916V6.08325Z"
                  fill="white"
                />
                <path
                  d="M44.1041 54.7499V50.3456C50.2057 49.3358 53.0497 45.5306 53.0497 41.035C53.0497 36.3539 49.9776 33.7411 43.5536 32.275V24.6557C45.7558 25.1606 47.2249 26.1674 48.326 27.4084L52.4991 23.6458C50.4795 21.3524 47.7754 19.9289 44.1041 19.5183V15.2083H38.8725V19.5213C32.9564 20.2088 29.7931 23.4208 29.7931 28.2357C29.7931 32.6857 32.5914 35.7121 39.4717 37.1356V45.3907C36.9015 45.0257 34.748 43.7878 33.0021 41.9536L28.8745 45.7131C31.1679 48.2377 34.2431 50.1175 38.8755 50.5311V54.7499H44.1072H44.1041ZM36.1228 28.0532C36.1228 26.2647 37.1326 25.0237 39.4686 24.5675V31.3565C37.1326 30.7116 36.1228 29.7961 36.1228 28.0532ZM46.72 41.54C46.72 43.2828 45.7101 44.7033 43.5566 45.2995V38.0512C46.1694 38.8329 46.72 40.2108 46.72 41.54Z"
                  fill="#200D0D"
                />
                <path
                  d="M12.1666 63.875C13.78 63.875 15.3273 63.2341 16.4681 62.0932C17.609 60.9523 18.2499 59.405 18.2499 57.7917V36.5H6.08325V57.7917C6.08325 59.405 6.72416 60.9523 7.86503 62.0932C9.00587 63.2341 10.5532 63.875 12.1666 63.875Z"
                  fill="#200D0D"
                />
              </svg>
              Futures Bot
            </div>
            {/* </h1> */}

            <div className="flex justify-center w-full mt-2">
              <div className=" flex w-full md:w-[20%] justify-center gap-3">
                <div>
                  <div className="flex flex-col items-start justify-center">
                    Pnl:{' '}
                    <span

                      className="bg-black flex items-center w-[100px] p-2 rounded-md px-3"
                      style={{ color: pnl > 0 ? "green" : "white" }}

                    >
                      {pnl} USDT
                    </span>{' '}
                  </div>
                </div>

                <div className="flex flex-col items-start justify-center">
                  Status:{' '}
                  <span
                    className="p-2 rounded-md flex w-fit items-center px-3"
                    style={{
                      backgroundColor: futuresActive ? '#D0FFB4' : '#FFE5B4',
                      color: futuresActive ? '#007A29' : '#B47700',
                      // borderRadius: "5px",
                      // border: "black solid 2px",
                    }}
                  >
                    {futuresActive ? 'Active' : 'Inactive'}
                    <span
                      className=" ml-2 w-2 h-2 rounded-full mt-2 "
                      style={{
                        backgroundColor: futuresActive ? '#007A29' : '#B47700',
                      }}
                    ></span>
                  </span>
                </div>
              </div>
            </div>
            <Row className="flex w-full  justify-content-center mt-2">
              <Col xs="12" md="8" className="p-0">
                <div className=" pl-2">
                  {futuresDollarAmountInvalid && (
                    <Alert color="primary" className="mt-3">
                      The minimum required dollar amount to access this service
                      is $200. We're here to help you make the most of your
                      investment!
                    </Alert>
                  )}
                  <InputGroup className="full-width margin-10 flex justify-center mt-3">
                    <Row className="flex w-full flex-col justify-center items-center">
                      <Col>
                        <Label className="w-full text-lg">
                          Investment amount ( in USD )
                        </Label>
                      </Col>
                      <Col xs="6">
                        <Input
                          type="number"
                          className=" rounded-md bg-gray-100"
                          value={futuresDollarAmount}
                          readOnly={futuresActive}
                          onChange={(e) =>
                            setFuturesDollarAmount(e.target.value)
                          }
                          invalid={futuresDollarAmountInvalid}
                        />
                        {futuresDollarAmountInvalid && (
                          <FormFeedback>
                            Amount more than $200 is required!
                          </FormFeedback>
                        )}
                      </Col>
                    </Row>

                    <Row className=" mt-5 w-full flex flex-col justify-center items-center p-0">
                      <Col>
                        <Label className="flex w-full justify-center text-lg">
                          Leverage
                        </Label>
                      </Col>
                      <Col className="flex justify-center w-full mt-3 p-0">
                        <div className="flex w-full md:w-[30%] justify-center gap-3">
                          {futuresLeverageOptions.map((option, index) => {
                            return (
                              <Button
                                key={index}
                                color="black"
                                style={{

                                  background: randombg[index],
                                }}
                                className={`
                                  ${
                                    leverage === option
                                      ? "active-leverage-btn"
                                      : ""
                                  }
                                    text-black rounded-md flex w-fit  font-[15px]
                                `}

                                onClick={() => setLeverage(option)}
                              >
                                {option}x
                              </Button>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </InputGroup>
                  {leverage !== 1 && (
                    <Alert
                      color="primary"
                      className="mt-3 ml-6 w-[90%] text-sm"
                    >
                      To utilize {leverage}x leverage, users should have at
                      least double their investment amount in their futures
                      wallet for optimal trading flexibility.
                    </Alert>
                  )}
                  <Row className="flex justify-center w-full ml-3">
                    <Button
                      color="primary"
                      disabled={loading}
                      className="w-fit rounded-lg mt-5"
                      style={{
                        backgroundColor: futuresActive ? 'white' : 'black',
                        color: futuresActive ? 'black' : 'white',

                        padding: '10px',
                      }}
                      onClick={() => {
                        if (futuresActive) {
                          //stop
                          stopBot();
                        } else {
                          //start
                          startBot();
                        }
                      }}
                    >
                      <div className="flex items-center ">
                        {loading ? (
                          <Spinner size={'sm'} />
                        ) : futuresActive ? (
                          'DELETE BOT'
                        ) : (
                          'START BOT'
                        )}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="ml-2"
                          width="25"
                          height="25"
                          viewBox="0 0 48 48"
                          fill="none"
                        >
                          <path
                            d="M24 30C27.3137 30 30 27.3137 30 24C30 20.6863 27.3137 18 24 18C20.6863 18 18 20.6863 18 24C18 27.3137 20.6863 30 24 30Z"
                            fill={futuresActive ? 'black' : 'white'}
                          />
                          <path
                            d="M13.5 23.9999C13.5 20.5405 15.2393 17.5117 17.8419 15.8405L15.6725 13.5425C15.0769 13.9813 14.5221 14.4727 14.0146 15.0109C11.7482 17.412 10.5 20.6044 10.5 23.9999C10.5 27.3954 11.7482 30.5878 14.0147 32.9889C14.5222 33.5271 15.077 34.0185 15.6726 34.4572L17.842 32.1593C15.2393 30.4882 13.5 27.4593 13.5 23.9999ZM34.5 23.9999C34.5 27.4593 32.7607 30.4882 30.1581 32.1593L32.3275 34.4572C32.9231 34.0185 33.4779 33.5271 33.9854 32.9889C36.2518 30.5878 37.5 27.3954 37.5 23.9999C37.5 20.6044 36.2518 17.412 33.9853 15.011C33.4778 14.4728 32.923 13.9814 32.3274 13.5426L30.158 15.8406C32.7607 17.5117 34.5 20.5405 34.5 23.9999Z"
                            fill={futuresActive ? 'black' : 'white'}
                          />
                          <path
                            d="M6 24C6 18.7896 8.36728 14.165 12.0247 11.2672L9.88434 9C9.31722 9.47301 8.77885 9.97947 8.27213 10.5167C6.62006 12.2669 5.32256 14.3058 4.41581 16.5768C3.47634 18.9297 3 21.4271 3 24C3 26.5728 3.47634 29.0704 4.41581 31.4232C5.32256 33.6944 6.62006 35.7332 8.27213 37.4834C8.7789 38.0206 9.31726 38.527 9.88434 39.0001L12.0246 36.7329C8.36728 33.835 6 29.2104 6 24ZM42 24C42 29.2104 39.6327 33.835 35.9754 36.7329L38.1156 38.9686C38.6781 38.4998 39.2162 38.0253 39.7279 37.4833C41.3799 35.7331 42.6774 33.6943 43.5842 31.4231C44.5236 29.0704 45 26.5728 45 24C45 21.4271 44.5236 18.9297 43.5842 16.5768C42.6774 14.3057 41.3799 12.2669 39.7279 10.5167C39.2212 9.97943 38.6828 9.47296 38.1156 9L35.9753 11.2672C39.6327 14.165 42 18.7896 42 24Z"
                            fill={futuresActive ? 'black' : 'white'}
                          />
                        </svg>
                      </div>
                    </Button>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default FuturesBot;
