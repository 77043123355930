import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyBx3O-fA7E9m1NLN_YRGC4lNIaceEpb4-Q",
  authDomain: "blockwave-ventures.firebaseapp.com",
  projectId: "blockwave-ventures",
  storageBucket: "blockwave-ventures.appspot.com",
  messagingSenderId: "163268152239",
  appId: "1:163268152239:web:861f0fa49a6f848dfc7d5a",
  measurementId: "G-3R1R0HTYCX",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);

export { firebase };
