import { Col, Row } from "reactstrap"


const Box = ({children, fullHeight=false}) =>{
    return (
        <Row style={fullHeight ? {height: '100%'} : {}}>
            <Col 
                xs="12"
                style={{
                    border: 'black solid 1px',
                    textAlign: 'center',
                    padding: '10px',
                    
                    }}>
                    {children}
            </Col>
        </Row>
    )
}

export default Box