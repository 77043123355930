import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "devextreme/dist/css/dx.light.css";
import "../styles/App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import LoginPage from "../pages/LoginPage";
import SignupPage from "../pages/SignupPage";

import CreateBrokerPage from "../pages/CreateBrokerPage";
import CreateBotPage from "../pages/CreateBotPage";

import BotsPage from "../pages/BotsPage";
import BrokersPage from "../pages/BrokersPage";
import BotDetailPage from "../pages/BotDetailPage";
// import ExamplePage from "../pages/ExamplePage";
import UserPage from "../pages/UserPage";
import NewSignUpPage from "../pages/NewSignupPage";
import UserDashboard from "../pages/UserDashboard";
import Affiliate from "../pages/AffiliatePage";
import AboutUsPage from "../pages/AboutUsPage";
import FAQPage from "../pages/FAQPage";
import UserEmailList from "../pages/SuperAdmin/UserEmailList";
import MassEmail from "../pages/SuperAdmin/MassEmail";
import BotControl from "../pages/SuperAdmin/BotControl";

import SpotBot from "../components/UserDashboard/SpotBot";
import FuturesBot from "../components/UserDashboard/FuturesBot";

import CustomNavbar from "../components/CustomNavbar";
import Footer from "../components/Footer";
const urlParams = new URLSearchParams(window.location.search);
const refParam = urlParams.get("ref");
if (refParam) localStorage.setItem("refferedBy", refParam);

function App() {
  return (
    <>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
          rel="stylesheet"
        ></link>
      </head>
      <Router>
        {/* <TradevisorNavbar /> */}
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route
            path="/dashboard"
            element={
              <>
                {" "}
                <CustomNavbar
                  navbarLinks={[
                    {
                      text: "Dashboard",
                      url: "/dashboard",
                    },
                    {
                      text: "Spot",
                      url: "/spot",
                    },
                    {
                      text: "Futures",
                      url: "/futures",
                    },
                    {
                      text: "Affiliate",
                      url: "/affiliate",
                    },
                  ]}
                />
                <UserDashboard />
                <Footer />
              </>
            }
          />
          <Route
            path="/spot"
            element={
              <>
                {" "}
                <CustomNavbar
                  navbarLinks={[
                    {
                      text: "Dashboard",
                      url: "/dashboard",
                    },
                    {
                      text: "Spot",
                      url: "/spot",
                    },
                    {
                      text: "Futures",
                      url: "/futures",
                    },
                    {
                      text: "Affiliate",
                      url: "/affiliate",
                    },
                  ]}
                />
                <SpotBot />
                <Footer />
              </>
            }
          />
          <Route
            path="/futures"
            element={
              <>
                {" "}
                <CustomNavbar
                  navbarLinks={[
                    {
                      text: "Dashboard",
                      url: "/dashboard",
                    },
                    {
                      text: "Spot",
                      url: "/spot",
                    },
                    {
                      text: "Futures",
                      url: "/futures",
                    },
                    {
                      text: "Affiliate",
                      url: "/affiliate",
                    },
                  ]}
                />
                <FuturesBot />
                <Footer />
              </>
            }
          />
          <Route path="/createbroker" element={<CreateBrokerPage />} />
          <Route path="/createbot" element={<CreateBotPage />} />
          <Route path="/bots" element={<BotsPage />} />
          <Route path="/bot/:botId" element={<BotDetailPage />} />
          <Route path="/brokers" element={<BrokersPage />} />
          {/* <Route path="/example" element={<ExamplePage />} /> */}
          <Route path="/user" element={<UserPage />} />
          <Route path="/new" element={<NewSignUpPage />} />
          <Route
            path="/affiliate"
            element={
              <>
                {" "}
                <CustomNavbar
                  navbarLinks={[
                    {
                      text: "Dashboard",
                      url: "/dashboard",
                    },
                    {
                      text: "Spot",
                      url: "/spot",
                    },
                    {
                      text: "Futures",
                      url: "/futures",
                    },
                    {
                      text: "Affiliate",
                      url: "/affiliate",
                    },
                  ]}
                />
                <Affiliate />
                <Footer />
              </>
            }
          />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/faq" element={<FAQPage />} />
          <Route path="/superadmin/dashboard" element={<UserEmailList />} />
          <Route path="/superadmin/massemail" element={<MassEmail />} />
          <Route path="/superadmin/bot" element={<BotControl />} />
        </Routes>
        <ToastContainer />
      </Router>
    </>
  );
}

export default App;
