import {
  Button,
  Col,
  Container,
  Input,
  Row,
  Table,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Tooltip,
  Alert,
} from 'reactstrap';

import Swal from 'sweetalert2';
import { BsFacebook, BsTwitter, BsSendFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import api from '../apis/axios';
import moment from 'moment';
import { useSelector } from 'react-redux';

import copy from 'copy-to-clipboard';

const Affiliate = () => {
  const [inputwallet, setInputwallet] = useState('');
  const [error, setError] = useState('');
  const [affiliates, setAffiliates] = useState([]);
  const [user, setUser] = useState('');
  const { authToken } = useSelector((state) => state.auth);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    api
      .get('/refLink', { headers: { AuthToken: authToken } })
      .then((response) => {
        setUser(response.data.user);
        //   console.log(response.data.user.rewardWalletAddress);
      })
      .catch((err) => {
        console.log(err);
      });

    api
      .get('/getAffiliates', { headers: { AuthToken: authToken } })
      .then((response) => {
        //  console.log(response.data.affiliates);
        if (response.data?.affiliates?.length > 0) {
          setAffiliates(response.data.affiliates);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [authToken]);

  const addWallet = async () => {
    if (isValidBEP20Address(inputwallet)) {
      //  console.log(inputwallet);
      api
        .post(
          '/updateRewardAddress',
          { rewardWalletAddress: inputwallet },
          { headers: { AuthToken: authToken } },
        )
        .then((res) => {
          setUser({ ...user, rewardWalletAddress: inputwallet });
          Swal.fire({
            toast: true,
            icon: 'success',
            title: 'Reward Address Added',
            animation: true,
            position: 'bottom',
            showConfirmButton: false,
            timer: 3000,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setError('Invalid Address Format');
    }
  };

  const affiliateLink = 'https://app.blockwaveventures.com/?ref=';

  return (
    <>
      <div className="bgcoverAffiliate"></div>

      {/* <h1 className=" font-montserrat flex w-full justify-start pl-10 items-center text-[30px]"> */}{" "}
      <div className=" w-full p-3 h-0 text-[30px] font-montserrat  text-white relative -top-[100px] flex justify-center items-start">
        <svg
          width="38"
          height="38"
          style={{ marginRight: "10px" }}
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.98539 10.205C3.25498 9.87581 3.38978 9.7112 3.52076 9.75811C3.65173 9.80502 3.65134 10.0334 3.65055 10.4902C3.65054 10.4959 3.65054 10.5017 3.65054 10.5075C3.65054 15.9432 8.05704 20.3497 13.4927 20.3497C13.4985 20.3497 13.5043 20.3497 13.5101 20.3497C13.9668 20.3489 14.1952 20.3485 14.2421 20.4795C14.289 20.6105 14.1244 20.7453 13.7952 21.0149C12.4707 22.0995 10.7772 22.7502 8.93172 22.7502C4.68922 22.7502 1.25 19.311 1.25 15.0685C1.25 13.223 1.90079 11.5296 2.98539 10.205Z"
            fill="#ffffff"
          />
          <path
            opacity="0.4"
            d="M5.25 10.0002C5.25 5.16775 9.16751 1.25024 14 1.25024C18.8325 1.25024 22.75 5.16775 22.75 10.0002C22.75 14.8327 18.8325 18.7502 14 18.7502C9.16751 18.7502 5.25 14.8327 5.25 10.0002Z"
            fill="#ffffff"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9851 5.25024C14.3993 5.25024 14.7351 5.58603 14.7351 6.00024L14.7351 6.24041C15.6246 6.48482 16.3557 7.15611 16.5399 8.0751C16.6213 8.48124 16.358 8.87646 15.9519 8.95785C15.5457 9.03923 15.1505 8.77597 15.0691 8.36983C14.9996 8.02285 14.6035 7.63931 13.9851 7.63931C13.2805 7.63931 12.8879 8.11795 12.8879 8.50248C12.8879 8.80299 12.9662 8.89441 13.0253 8.94152C13.1229 9.01936 13.3789 9.13519 13.9851 9.13519C14.7147 9.13519 15.4221 9.25488 15.9547 9.66599C16.5306 10.1105 16.7502 10.7713 16.7502 11.4984C16.7502 12.2998 16.4209 12.9385 15.8445 13.3483C15.5139 13.5833 15.1306 13.7233 14.7351 13.7962V14.0002C14.7351 14.4145 14.3993 14.7502 13.9851 14.7502C13.5709 14.7502 13.2351 14.4145 13.2351 14.0002V13.7724C12.2888 13.5446 11.4755 12.8911 11.2674 11.938C11.1791 11.5333 11.4355 11.1336 11.8402 11.0453C12.2449 10.9569 12.6445 11.2134 12.7329 11.618C12.802 11.9346 13.2345 12.3615 13.9851 12.3615C14.4551 12.3615 14.7844 12.2616 14.9754 12.1258C15.1283 12.017 15.2502 11.8491 15.2502 11.4984C15.2502 11.0732 15.1339 10.9273 15.0381 10.8534C14.899 10.746 14.5988 10.6352 13.9851 10.6352C13.2478 10.6352 12.5803 10.5051 12.0901 10.1143C11.5615 9.69282 11.3879 9.09289 11.3879 8.50248C11.3879 7.37241 12.2129 6.52457 13.2351 6.24157L13.2351 6.00024C13.2351 5.58603 13.5709 5.25024 13.9851 5.25024Z"
            fill="#111111"
          />
        </svg>
        Affiliate Program
      </div>
      {/* </h1> */}

      <Container fluid>
        <Row>
          <Col
            xs="12"

            className="d-flex justify-content-center  font-montserrat"
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <center>
              <Alert color="primary" className="w-full md:w-[50%]">

                Refer friends; earn 5% from their profits via your affiliate
                link.
              </Alert>
            </center>

            {user.rewardWalletAddress != null && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                  className="my-2"
                  color="dark"
                  inverse
                  style={{
                    width: '11rem',
                  }}
                >
                  <CardBody>
                    <CardTitle tag="h2">{affiliates?.length}</CardTitle>
                    <CardText>Affiliates</CardText>
                  </CardBody>
                </Card>
                <Card
                  className="my-1"
                  color="success"
                  inverse
                  style={{
                    width: '11rem',
                    marginLeft: '40px',
                  }}
                >
                  <CardBody>
                    <CardTitle tag="h2">
                      ${affiliates?.reduce((a, b) => a + b.reward, 0)}.00
                    </CardTitle>
                    <CardText>Total Reward</CardText>
                  </CardBody>
                </Card>
              </div>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Row
                className="justify-content-center mt-5"
                style={{ width: '50%' }}
              >
                <Col xs="11">
                  {affiliates.length > 0 && (
                    <Table striped bordered>
                      <thead className="thead-light">
                        <tr>
                          <th
                            style={{
                              backgroundColor: 'black',
                              color: 'white',
                            }}
                            scope="col"
                          >
                            Email
                          </th>
                          <th
                            style={{
                              backgroundColor: 'black',
                              color: 'white',
                            }}
                            scope="col"
                          >
                            Joined On
                          </th>
                          <th
                            style={{
                              backgroundColor: '#198753',
                              color: 'white',
                            }}
                            scope="col"
                          >
                            Reward
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {affiliates.map((affiliate, id) => {
                          let joined = moment(affiliate.joined).format(
                            'DD MMM YY',
                          );
                          return (
                            <tr key={id}>
                              <td>
                                {affiliate.email}{' '}
                                {!affiliate.isVerified && (
                                  <span>
                                    <Tooltip
                                      isOpen={tooltipOpen}
                                      target="TooltipExample"
                                      toggle={toggle}
                                    >
                                      non-verified user
                                    </Tooltip>
                                    <svg
                                      viewBox="0 0 15 15"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      id="TooltipExample"
                                      style={{ color: '#D35400' }}
                                    >
                                      <path
                                        d="M7 4.5V5h1v-.5H7zm1-.01v-.5H7v.5h1zM8 11V7H7v4h1zm0-6.5v-.01H7v.01h1zM6 8h1.5V7H6v1zm0 3h3v-1H6v1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zM1 7.5A6.5 6.5 0 017.5 1V0A7.5 7.5 0 000 7.5h1zM7.5 14A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zm0 1A7.5 7.5 0 0015 7.5h-1A6.5 6.5 0 017.5 14v1z"
                                        fill="currentColor"
                                      ></path>
                                    </svg>
                                  </span>
                                )}
                              </td>
                              <td>{joined}</td>
                              <td>${affiliate.reward}.00</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  )}
                </Col>
              </Row>
            </div>

            <Row className="d-flex justify-content-center mt-2">
              <Col xs="11" md="8">
                <div className=" border  mb-12">
                  {user.rewardWalletAddress != null ? (
                    <div>
                      <div style={{ marginBottom: '20px' }}>
                        <Row className="mt-5">
                          <Col xs="3">
                            <h3>Affiliate Link</h3>
                          </Col>
                          <Col xs="6">
                            <Input
                              type="text"
                              readOnly
                              value={affiliateLink + user.username}
                            />
                          </Col>
                          <Col xs="3">
                            <span
                              onClick={() => {
                                copy(affiliateLink + user.username);
                                Swal.fire({
                                  toast: true,
                                  icon: 'success',
                                  title: 'Link Copied',
                                  animation: true,
                                  position: 'bottom',
                                  showConfirmButton: false,
                                  timer: 3000,
                                });
                              }}
                              className=" flex underline decoration-dotted underline-offset-4 text-gray-400 cursor-pointer hover:text-gray-700"
                            >
                              Copy Link{' '}
                              <svg
                                stroke="currentColor"
                                fill="currentColor"
                                stroke-width="0"
                                viewBox="0 0 24 24"
                                className="ml-1 mt-1 "
                                height="1em"
                                width="1em"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M20 2H10c-1.103 0-2 .897-2 2v4H4c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2v-4h4c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zM4 20V10h10l.002 10H4zm16-6h-4v-4c0-1.103-.897-2-2-2h-4V4h10v10z"></path>
                              </svg>
                            </span>
                          </Col>
                        </Row>
                      </div>
                      <div
                        style={{
                          width: '60%',

                          margin: 'auto',
                        }}
                      >
                        <span
                          style={{
                            marginTop: '20px',
                          }}
                        >
                          <strong style={{}}>Reward Address: </strong>
                          {user.rewardWalletAddress.slice(0, 6)}....
                          {user.rewardWalletAddress.slice(-4)}
                        </span>
                        <span
                          className=" text-gray-400 hover:text-gray-700"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            setUser({ ...user, rewardWalletAddress: null });
                          }}
                        >
                          <svg
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="19"
                            style={{ marginLeft: '10px', marginBottom: '2 px' }}
                          >
                            <path
                              d="M4.5 8.5l-.354-.354L4 8.293V8.5h.5zm4-4l.354-.354a.5.5 0 00-.708 0L8.5 4.5zm2 2l.354.354a.5.5 0 000-.708L10.5 6.5zm-4 4v.5h.207l.147-.146L6.5 10.5zm-2 0H4a.5.5 0 00.5.5v-.5zm3 3.5A6.5 6.5 0 011 7.5H0A7.5 7.5 0 007.5 15v-1zM14 7.5A6.5 6.5 0 017.5 14v1A7.5 7.5 0 0015 7.5h-1zM7.5 1A6.5 6.5 0 0114 7.5h1A7.5 7.5 0 007.5 0v1zm0-1A7.5 7.5 0 000 7.5h1A6.5 6.5 0 017.5 1V0zM4.854 8.854l4-4-.708-.708-4 4 .708.708zm3.292-4l2 2 .708-.708-2-2-.708.708zm2 1.292l-4 4 .708.708 4-4-.708-.708zM6.5 10h-2v1h2v-1zm-1.5.5v-2H4v2h1z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </div>

                      <Row
                        className="mt-4 mb-5"
                        style={{ justifyContent: 'center' }}
                      >
                        <Col xs="2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://www.facebook.com/sharer/sharer.php?u=${affiliateLink}${user.username}`}
                            className=" text-zinc-700 hover:text-zinc-900"
                          >
                            <BsFacebook fontSize={'30px'} />
                          </a>
                        </Col>
                        <Col xs="2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://twitter.com/intent/tweet?url=${affiliateLink}${user.username}`}
                            className=" text-zinc-700 hover:text-zinc-900"
                          >
                            <BsTwitter fontSize={'30px'} />
                          </a>
                        </Col>
                        <Col xs="2">
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://t.me/share/url?url=${affiliateLink}${user.username}`}
                            className=" text-zinc-700 hover:text-zinc-900"
                          >
                            <BsSendFill fontSize={'30px'} />
                          </a>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className=" pt-4 font-montserrat text-sm md:text-lg">
                      Affiliate link will be available once you have added your
                      wallet address for recieving rewards.
                      <div style={{ marginTop: '20px' }}>
                        <label>

                          {" "}
                          <h3 className="text-sm md:text-lg">
                            Enter Reward Address (BEP-20 / BSC-20)
                          </h3>

                        </label>
                        <br />
                        <div style={{ color: 'red' }}>{error && error}</div>
                        <input
                          style={{
                            width: '75%',
                            border: error ? '1px solid red' : '',
                          }}
                          value={inputwallet}
                          onChange={(e) => {
                            setError('');
                            setInputwallet(e.target.value);
                          }}
                          type="text"
                          placeholder="0x0000000"
                        />
                        <br />
                        <Button
                          color="primary"
                          className="bg-[#1D1728]  rounded font-montserrat text-sm md:text-lg hover:bg-slate-800 border-none"
                          // disabled={loading}
                          style={{

                            maxWidth: "200px",
                            color: "white",
                            padding: "10px",
                            marginTop: "20px",
                            marginBottom: "20px",

                          }}
                          onClick={() => {
                            addWallet();
                          }}
                        >
                          Join Program{' '}
                          <svg
                            viewBox="0 0 15 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                          >
                            <path
                              d="M13.5 7.5l-4-4m4 4l-4 4m4-4H1"
                              stroke="currentColor"
                            ></path>
                          </svg>
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};
function isValidBEP20Address(address) {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}
export default Affiliate;
