const Footer = () => {
  return (
    <>

      <section class="footer w-full bg-black sm:h-[250px] md:h-full md:p-6 mt-[100px]  font-montserrat">
        <div class="flex flex-col  md:flex-row justify-start items-center md:pt-5 pt-2 ">
          <div>
            {/* <img src="./img/Group 182.png" alt="logo" class="md:w-52 w-48" /> */}
            <span style={{ marginRight: "10px" }}>
              <a
                href="#"
                className="w-full flex items-center mt-3 justify-center"
              >
                <img
                  alt="Logo"
                  className="h-10 max-w-[65px]"
                  src="/new-logo-white.png"
                />
                <img
                  alt="Logo"
                  className="ml-2 h-10 max-w-[140px]"
                  src="/text-logo-white.svg"
                />
              </a>

            </span>
            <p className=" text-white font-montserrat md:text-[21px] text-lg font-light leading-[4]">
              Ride Crypto Wave, Effortlessly
            </p>
          </div>
        </div>

        <div className="md:flex md:justify-between md:flex-row md:items-center flex justify-center items-center flex-col   ">
          <div>
            <span className="text-[#717171] font-paragraph font-light text-md">
              Copyright 2023 - Blockchain Ventures
            </span>
          </div>
          <div>

            <ul class="flex flex-col  justify-between items-center  md:flex-row md:gap-6 gap-4 ">
              <span class="text-[#717171] font-paragraph font-light md:text-md text-sm">

                <a
                  href="https://www.blockwaveventures.com/Privacypolicy"
                  className="text-[#717171] no-underline"
                >
                  Privacy Policy
                </a>
              </span>
              <span className=" font-montserrat font-light md:text-base text-[12px]">
                <a
                  href="https://www.blockwaveventures.com/TermsConditions"
                  className="text-[#717171] no-underline"
                >
                  Terms & Conditions
                </a>
              </span>
              <span className="text-[#717171] font-montserrat font-light text-base text-[12px]">
                <a
                  href="mailto:support@blockwaveventures.com"
                  className="text-[#717171] no-underline"
                >
                  Support
                </a>
              </span>

              <div className="flex gap-3">
                <span>
                  <a href="#">
                    <img src="/linkdinsvg.svg" alt="Linkdin" class="w-6" />
                  </a>
                </span>
                <span>
                  <a href="#">
                    <img src="/twitter.svg" alt="Linkdin" class="w-6" />
                  </a>
                </span>
                <span>
                  <a href="#">
                    <img src="/Fbe.svg" alt="Linkdin" class="w-6" />
                  </a>
                </span>
              </div>

            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
