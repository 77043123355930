import { createSlice } from "@reduxjs/toolkit";
import api from "../apis";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    authToken: "",
    email: "",
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    clearAuthToken: (state) => {
      state.authToken = "";
    },
    setAuthEmail: (state, action) => {
      state.email = action.payload;
    },
    clearAuthEmail: (state) => {
      state.email = "";
    },
    logout: (state) => {
      state.email = "";
      state.authToken = "";
    },
  },
});
export const {
  setAuthToken,
  clearAuthToken,
  setAuthEmail,
  clearAuthEmail,
  logout,
} = authSlice.actions;
export default authSlice.reducer;

export const createUser = (user) => async (dispatch, getState) => {
  var authToken = getState().auth.authToken;
  api
    .userExists(authToken)
    .then((response) => {
      if (!response.data.exists) {
        // only run if user already doesn't exists
        api
          .createUser(user, authToken)
          .then((response) => {})
          .catch((error) => {});
      }
    })
    .catch((err) => {});
};
