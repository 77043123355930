import {
  Form,
  FormGroup,
  InputGroup,
  InputGroupText,
  Input,
  Button,
  Row,
  Col,
  Alert,
  Spinner,
} from 'reactstrap';
import Panel from '../BasicUIElements/Panel';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import api from '../../apis';
import { toast } from 'react-toastify';

const InputAPIKeys = () => {
  const { authToken } = useSelector((state) => state.auth);

  const [apiKey, setApiKeyState] = useState('');
  const [apiSecret, setApiSecretState] = useState('');
  const [apiKeyError, setApiKeyError] = useState(null);
  const [apiSecretError, setApiSecretError] = useState(null);
  const [binanceAccountDuplicate, setBinanceAccountDuplicate] = useState(false);
  const [binanceAlias, setBinanceAlias] = useState('');
  const [connectLoading, setConnectLoading] = useState(false);

  const validateApiKeys = () => {
    if (apiKey.trim() === '') {
      setApiSecretError("API Secret can't be empty!");
    }
    if (apiKey.trim() === '') {
      setApiKeyError("API Key can't be empty!");
    }
  };

  const checkForDuplicateBinanceAccount = async () => {
    const response = await api.binanceAccountExists(
      {
        apiKeys: {
          apiKey: apiKey,
          apiSecret: apiSecret,
        },
      },
      authToken,
    );

    if (response.data.binanceAccountAlreadyExists === false) {
      // new binance account
      setBinanceAccountDuplicate(false);
      setBinanceAlias(response.data.binanceAccountAlias);
    } else {
      //binance account already exists
      setBinanceAccountDuplicate(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setConnectLoading(true);
    validateApiKeys();
    if (!apiKeyError && !apiSecretError) {
      await checkForDuplicateBinanceAccount();
      if (binanceAccountDuplicate === false) {
        // saves the alias and API Keys.
        await api.saveApi(
          {
            broker: 'BINANCE',
            apiKey,
            apiSecret,
          },
          authToken,
        );
        await api.saveBinanceAlias(
          {
            binanceAccountAlias: binanceAlias,
          },
          authToken,
        );
        setConnectLoading(true);
        toast.success('Binance API Keys added!', {
          position: 'bottom-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        });
      } else {
        // binance Account Already Exists
        setConnectLoading(false);
      }
    } else {
      setConnectLoading(false);
    }
  };

  return (
    <Row className="d-flex justify-content-center" style={{ width: '100%' }}>
      <Col className="justify-content-center" sm="11" lg="7">
        <Panel
          className="d-flex justify-content-center"
          style={{ flexDirection: 'column' }}
        >
          <h3>Connect to Binance</h3>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <h6 className="m-4">Input the API Keys for you Broker</h6>
              <InputGroup className={'mt-4'}>
                <InputGroupText className="input-text"> API Key</InputGroupText>
                <Input
                  xs="9"
                  type="text"
                  value={apiKey}
                  onChange={(e) => setApiKeyState(e.target.value)}
                />
              </InputGroup>
              {apiKeyError && <div className="text-danger">{apiKeyError}</div>}

              <InputGroup className={'mt-4'}>
                <InputGroupText className="input-text">
                  API Secret
                </InputGroupText>
                <Input
                  type="password"
                  value={apiSecret}
                  onChange={(e) => setApiSecretState(e.target.value)}
                />
              </InputGroup>
              {apiSecretError && (
                <div className="text-danger">{apiSecretError}</div>
              )}

              <Button className={'mt-5'} type="primary">
                {connectLoading ? <Spinner /> : 'Connect'}
              </Button>
              {binanceAccountDuplicate && (
                <Alert color="danger">Binance Account Already Exists!</Alert>
              )}
            </FormGroup>
          </Form>
          <Alert color="primary">
            Guide to creating API keys on Binance: Guide
          </Alert>
        </Panel>
      </Col>
    </Row>
  );
};

export default InputAPIKeys;
