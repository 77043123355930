import { Col, Container, Row } from 'reactstrap';
import CustomNavbar from '../components/CustomNavbar';

import { useEffect, useState } from 'react';
import Page0 from '../components/NewSignupPage/Page0';

import BinanceAPIPage from '../components/NewSignupPage/BinanceAPIPage';

import { addBinanceAPI } from '../redux/binanceApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import api from '../apis';
import axios from '../apis/axios';

const NewSignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { apiCreationSuccess } = useSelector((state) => state.binanceApi);

  const [pageNumber, setPageNumber] = useState(0);
  const { authToken } = useSelector((state) => state.auth);

  useEffect(() => {
    console.log('mooo888');

    api.getUser(authToken).then((res) => {
      const refferedBy = localStorage.getItem('refferedBy');
      if (refferedBy && !res.data.user.affiliatedWith) {
        axios
          .post(
            '/addRefferal',
            { affiliatedWith: refferedBy },
            { headers: { AuthToken: authToken } },
          )
          .then(() => {
            localStorage.removeItem('refferedBy');
          })
          .catch((err) => {
            console.error('Error adding referral:', err);
          });
      }
    });
    if (apiCreationSuccess === true) {
      navigate('/dashboard', { replace: true });
    }
  }, [apiCreationSuccess, authToken, navigate]);

  const nextPage = () => {
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <>
      <CustomNavbar />
      <Container fluid>
        {pageNumber === 0 && <Page0 />}
        {pageNumber === 1 && <BinanceAPIPage />}
        {/* {pageNumber === 2 && <BinanceAPIPage />} */}

        {/* Navigation Panel */}
        <Row className="flex flex-col md:flex-row items-center w-full mt-4 mb-5 ml-0 md:ml-[150px]">
          {pageNumber > 0 && (
            <Col
              style={{

                alignSelf: "flex-center",
                cursor: "pointer",

              }}
              onClick={previousPage}
            >
              <span className="text-[#362F43] flex items-center font-montserrat text-xl ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="88"
                  height="68"
                  viewBox="0 0 88 68"
                  className="w-4 h-4 flex items-center mr-3"
                  fill="none"
                >
                  <path
                    d="M0.88279 31.5456L29.1778 0.820946C29.7792 0.261665 30.5529 -0.0305862 31.3441 0.00260162C32.1354 0.0357895 32.8859 0.39196 33.4458 0.999947C34.0057 1.60793 34.3338 2.42296 34.3643 3.28214C34.3949 4.14132 34.1257 4.9814 33.6107 5.63447L10.6917 30.5556L84.8561 30.5556C85.6899 30.5556 86.4896 30.9152 87.0792 31.5555C87.6688 32.1957 88 33.064 88 33.9694C88 34.8748 87.6688 35.7432 87.0792 36.3834C86.4896 37.0236 85.6899 37.3833 84.8561 37.3833L10.6917 37.3833L33.6421 62.2702C34.1572 62.9233 34.4263 63.7634 34.3958 64.6226C34.3652 65.4818 34.0372 66.2968 33.4773 66.9048C32.9174 67.5128 32.1668 67.8689 31.3756 67.9021C30.5843 67.9353 29.8107 67.6431 29.2092 67.0838L0.914223 36.3591C0.328667 35.7195 0 34.8543 0 33.9524C0 33.0505 0.328667 32.1852 0.914223 31.5456H0.88279Z"
                    fill="black"
                  />
                  <path
                    d="M0.88279 31.5456L29.1778 0.820946C29.7792 0.261665 30.5529 -0.0305862 31.3441 0.00260162C32.1354 0.0357895 32.8859 0.39196 33.4458 0.999947C34.0057 1.60793 34.3338 2.42296 34.3643 3.28214C34.3949 4.14132 34.1257 4.9814 33.6107 5.63447L10.6917 30.5556L84.8561 30.5556C85.6899 30.5556 86.4896 30.9152 87.0792 31.5555C87.6688 32.1957 88 33.064 88 33.9694C88 34.8748 87.6688 35.7432 87.0792 36.3834C86.4896 37.0236 85.6899 37.3833 84.8561 37.3833L10.6917 37.3833L33.6421 62.2702C34.1572 62.9233 34.4263 63.7634 34.3958 64.6226C34.3652 65.4818 34.0372 66.2968 33.4773 66.9048C32.9174 67.5128 32.1668 67.8689 31.3756 67.9021C30.5843 67.9353 29.8107 67.6431 29.2092 67.0838L0.914223 36.3591C0.328667 35.7195 0 34.8543 0 33.9524C0 33.0505 0.328667 32.1852 0.914223 31.5456H0.88279Z"
                    stroke="black"
                  />
                </svg>
                BACK
              </span>
            </Col>
          )}
          <Col

            className="flex justify-end items-center mr-0 md:mr-[150px]"
            // style={{
            //   alignSelf: "flex-end",
            //   textAlign: "right",
            //   fontSize: "30px",
            //   marginRight: "150px",
            //   marginBottom: "50px",
            // }}

          >
            <div
              className="underline text-xl mr-10 cursor-pointer"
              onClick={() => {
                navigate('/dashboard', { replace: true });
              }}
            >
              Add Later{' '}
            </div>
            <span
              className="bg-[#362F43] w-fit justify-end  px-4 py-2 text-white rounded-md text-xl flex items-center cursor-pointer"
              onClick={() => {
                if (pageNumber === 1) {
                  dispatch(addBinanceAPI());
                } else {
                  nextPage();
                }
              }}
            >
              NEXT
              <svg
                className="w-5 h-5 mb-1 ml-2"
                // xmlns="http://www.w3.org/2000/svg"
                // viewBox="0 0 24 24"
                id="right-arrow"
              >
                <g data-name="Layer 2">
                  <g data-name="arrowhead-right">
                    <rect
                      width="9"
                      height="9"
                      opacity="0"
                      transform="rotate(-90 12 12)"
                    ></rect>
                    <path
                      fill="white"
                      d="M18.78 11.37l-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"
                    ></path>
                    <path
                      fill="white"
                      d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"
                    ></path>
                  </g>
                </g>
              </svg>
            </span>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NewSignUpPage;
