import { useSelector } from 'react-redux';
import { BASE_URL } from '../config/urls';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useEffect } from 'react';

const useSuperAdminAuth = () => {
  const navigate = useNavigate();
  const authToken = useSelector((state) => state.auth.authToken);

  useEffect(() => {
    axios
      .get(BASE_URL + '/api/superadmin/auth', {
        headers: {
          AuthToken: authToken,
        },
      })
      .then((response) => {
        // setStatus('Auth\'d')
      })
      .catch((error) => {
        console.log(error);
        // setStatus('Not Auth\'d')
        navigate('/login', { replace: true });
      });
  }, [authToken, navigate]);
};

export default useSuperAdminAuth;
