import '../styles/LoginPage.css';

import SignupCard from '../components/SignupPage/SignupCard';

const SignupPage = () => {
  return (
    <>
      <div className="w-full h-screen flex flex-col lg:flex-row selection:bg-black selection:text-white">
        <div className="  flex flex-col mt-12 items-center w-full  space-y-2 bg-white dark:bg-zinc-900 lg:w-1/2 ">
          <img alt="Blockwave Logo" className="h-13 w-[40%]" src="/logo.svg" />

          <div className="w-full justify-center mt-5 ">
            <SignupCard />
          </div>
        </div>
        <div className=" hidden lg:block relative w-full bg-zinc-100 dark:bg-zinc-800 lg:w-1/2">
          <img
            alt="bg-sign "
            className="fixed h-full w-full object-fit "
            src="/bg-sign.png"
          />
          <div className="absolute bottom-0 left-0 p-6">
            <h1 className=" font-sans font-black text-white">
              Zero Barriers, Pure Opportunity
            </h1>
            <p className="text-lg text-zinc-100  font-sans font-light mt-7  gap-y-2 ">
              Get started for free with BlockWave Ventures — no registration or
              subscription fees, just unbridled crypto investment opportunities.
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="149"
              height="25"
              viewBox="0 0 149 25"
              fill="none"
            >
              <path
                d="M12.5 0L15.4463 8.4448L24.3882 8.63729L17.2672 14.0489L19.8473 22.6127L12.5 17.5125L5.15268 22.6127L7.73283 14.0489L0.611794 8.63729L9.55373 8.4448L12.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M12.5 0L15.4463 8.4448L24.3882 8.63729L17.2672 14.0489L19.8473 22.6127L12.5 17.5125L5.15268 22.6127L7.73283 14.0489L0.611794 8.63729L9.55373 8.4448L12.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M12.5 0L15.4463 8.4448L24.3882 8.63729L17.2672 14.0489L19.8473 22.6127L12.5 17.5125L5.15268 22.6127L7.73283 14.0489L0.611794 8.63729L9.55373 8.4448L12.5 0Z"
                fill="#CA8500"
              />
              <path
                d="M43.5 0L46.4463 8.4448L55.3882 8.63729L48.2672 14.0489L50.8473 22.6127L43.5 17.5125L36.1527 22.6127L38.7328 14.0489L31.6118 8.63729L40.5537 8.4448L43.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M43.5 0L46.4463 8.4448L55.3882 8.63729L48.2672 14.0489L50.8473 22.6127L43.5 17.5125L36.1527 22.6127L38.7328 14.0489L31.6118 8.63729L40.5537 8.4448L43.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M43.5 0L46.4463 8.4448L55.3882 8.63729L48.2672 14.0489L50.8473 22.6127L43.5 17.5125L36.1527 22.6127L38.7328 14.0489L31.6118 8.63729L40.5537 8.4448L43.5 0Z"
                fill="#CA8500"
              />
              <path
                d="M74.5 0L77.4463 8.4448L86.3882 8.63729L79.2672 14.0489L81.8473 22.6127L74.5 17.5125L67.1527 22.6127L69.7328 14.0489L62.6118 8.63729L71.5537 8.4448L74.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M74.5 0L77.4463 8.4448L86.3882 8.63729L79.2672 14.0489L81.8473 22.6127L74.5 17.5125L67.1527 22.6127L69.7328 14.0489L62.6118 8.63729L71.5537 8.4448L74.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M74.5 0L77.4463 8.4448L86.3882 8.63729L79.2672 14.0489L81.8473 22.6127L74.5 17.5125L67.1527 22.6127L69.7328 14.0489L62.6118 8.63729L71.5537 8.4448L74.5 0Z"
                fill="#CA8500"
              />
              <path
                d="M105.5 0L108.446 8.4448L117.388 8.63729L110.267 14.0489L112.847 22.6127L105.5 17.5125L98.1527 22.6127L100.733 14.0489L93.6118 8.63729L102.554 8.4448L105.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M105.5 0L108.446 8.4448L117.388 8.63729L110.267 14.0489L112.847 22.6127L105.5 17.5125L98.1527 22.6127L100.733 14.0489L93.6118 8.63729L102.554 8.4448L105.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M105.5 0L108.446 8.4448L117.388 8.63729L110.267 14.0489L112.847 22.6127L105.5 17.5125L98.1527 22.6127L100.733 14.0489L93.6118 8.63729L102.554 8.4448L105.5 0Z"
                fill="#CA8500"
              />
              <path
                d="M136.5 0L139.446 8.4448L148.388 8.63729L141.267 14.0489L143.847 22.6127L136.5 17.5125L129.153 22.6127L131.733 14.0489L124.612 8.63729L133.554 8.4448L136.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M136.5 0L139.446 8.4448L148.388 8.63729L141.267 14.0489L143.847 22.6127L136.5 17.5125L129.153 22.6127L131.733 14.0489L124.612 8.63729L133.554 8.4448L136.5 0Z"
                fill="#CAC200"
              />
              <path
                d="M136.5 0L139.446 8.4448L148.388 8.63729L141.267 14.0489L143.847 22.6127L136.5 17.5125L129.153 22.6127L131.733 14.0489L124.612 8.63729L133.554 8.4448L136.5 0Z"
                fill="#CA8500"
              />
            </svg>{' '}
            <span className="text-white text-xl  font-sans  font-thin  ml-2">
              5.0
            </span>
            <p className="text-white  font-sans  font-thin text-md mt-2">
              40+ Reviews
            </p>
          </div>
        </div>
      </div>

      {/* <AuthNavbar />
      <Container className="">
        <Row className="align-items-center justify-content-center ">
          <Col xs="11" md="8">
           
          </Col>
        </Row>
      </Container> */}
    </>
  );
};
export default SignupPage;
