import { Container } from 'reactstrap';

import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import api from '../apis';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../styles/UserDashboard.css';
import TradingViewWidget from '../components/TradingViewWidget';
import Alertbox from '../components/Dashboard/alertbox';
import ProfitBoxes from '../components/Dashboard/profitBoxes';
import CoinsPrice from '../components/Dashboard/coinsPrice';
import AffiliateAlert from '../components/Dashboard/affiliateAlert';

import axios from 'axios';

const UserDashboard = () => {
  useAuth();
  const navigate = useNavigate();

  const { authToken } = useSelector((state) => state.auth);

  const [payWarning, setPayWarning] = useState(false);
  const [payNotice, setPayNotice] = useState(false);
  const [coinsData, setCoinsData] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState('ETH');
  const [user, setUser] = useState();

  useEffect(() => {
    if (window.location.hash === '#futures') {
      // setSpotBotShown(false);
    }
    setTimeout(() => {
      api
        .getUser(authToken)
        .then((res) => {
          setUser(res.data.user);
          console.log(res.data.user);
          if (res.data.user.firstTime) {
            api
              .setFirstTimeFalse(authToken)
              .then((response) => {
                navigate('/new', { replace: true });
              })
              .catch((err) => {
                console.log(err);
                if (err.response.status === 404) {
                  navigate('/new', { replace: true });
                }
              });
          }

          if (res.data.user.payWarning) {
            setPayWarning(true);
          }
          if (res.data.user.payNotice) {
            setPayNotice(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 2500);

    getCoinsPrices();
  }, [authToken, navigate]);

  const getCoinsPrices = () => {
    axios
      .get(
        'https://api.coincap.io/v2/assets?ids=ethereum,bitcoin,binance-coin,xrp,solana',
      )
      .then((response) => {
        // Handle the successful response here
        setCoinsData(response.data.data);
        // console.log("Data from CoinCap API:", response.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error('Error fetching data from CoinCap API:', error);
      });
  };

  return (
    <>

      <div className="bgcoverAffiliate flex"></div>
      {/* <h1 className=" font-montserrat  flex w-full justify-start pl-10 items-center text-[30px]"> */}
      <div className=" w-full p-3 h-0 font-montserrat relative text-xxl text-[30px] font-bold text-white -top-[100px]  flex justify-center items-start">
        Welcome Back
      </div>
      {/* </h1> */}
      <div className="p-1 md:p-5 font-montserrat">

        <div className="flex">
          {/* <div className="text-[#777] text-[30px] font-extrabold ml-3">
          Mustafa Ali
        </div> */}
        </div>

        <div className="w-full flex flex-col md:flex-row ">
          <div className="w-full px-2">
            {payWarning && (
              <Alertbox
                title=""
                value="Your Invoice has been generated and Mailed to your Email
                    account."
                link={false}
                type="warning"
              />
            )}
            {payNotice && (
              <Alertbox
                title=""
                value="Your account has been disable due to non-payment of the Invoice!"
                link={false}
                type="error"
              />
            )}
            {user && !user.binanceAccountAlias && (
              <Alertbox
                title="Your account is not active."
                value="You need to link Exchange Account to your account to start trading via Blockwave"
                link={true}
                type="warning"
              />
            )}

            <ProfitBoxes
              spotDollarAmount={
                user && user.spotDollarAmount ? user.spotDollarAmount : 0
              }
              futuresDollarAmount={
                user && user.futuresDollarAmount ? user.futuresDollarAmount : 0
              }
            />

            <AffiliateAlert />
          </div>

          <div className="w-full p-2 ">
            <CoinsPrice
              coinsData={coinsData}
              setSelectedCoin={setSelectedCoin}
              selectedCoin={selectedCoin}
            />

            <Container fluid>
              <div className="h-[400px] w-full mt-5 ">
                <TradingViewWidget selectedCoin={selectedCoin} />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDashboard;
