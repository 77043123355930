import { createSlice } from '@reduxjs/toolkit';
// import UserList from "../components/SuperAdmin/UserList";

const userListSlice = createSlice({
  name: 'userList',
  initialState: {
    userList: [],
    selectedUser: [],
  },
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    appendSelectedUser: (state, action) => {
      state.selectedUser.push(action.payload); //= [...state.selectedUser, action.payload];
    },
    removeSelectedUser: (state, action) => {
      state.selectedUser = state.selectedUser.filter(
        (email) => email !== action.payload,
      );
    },
  },
});

export const {
  setUserList,
  setSelectedUser,
  appendSelectedUser,
  removeSelectedUser,
} = userListSlice.actions;
export default userListSlice.reducer;
