import React from "react";

export default function CoinsPrice(props) {
  let activeCoin = "bg-[#F8F3FF]  border border-[#B4A6C8] rounded-md px-2";
  return (
    <div className="flex flex-row justify-center gap-3 sm:mt-3 md:mt-0 items-center font-montserrat w-full flex-wrap cursor-pointer">
      {/* eth */}
      <div
        className={`flex items-center  cursor-pointer ${
          props?.selectedCoin === "ETH" ? activeCoin : null
        }`}
        onClick={() => {
          props.setSelectedCoin("ETH");
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
          >
            <path
              d="M6.58362 15.3867L15 1.64062L23.4164 15.3867L15 20.2898L6.58362 15.3867Z"
              fill="#727272"
            />
            <path
              d="M15 21.8344L23.1468 16.7766L15 28.3594L6.66089 16.7766L15 21.8344Z"
              fill="#727272"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-start p-2 text-sm md:text-lg">
          <div className="font-bold">ETH</div>
          <div>${parseFloat(props?.coinsData[1]?.priceUsd)?.toFixed(2)}</div>
        </div>
      </div>

      {/* btc */}
      <div
        className={`flex items-center  cursor-pointer ${
          props?.selectedCoin === "BTC" ? activeCoin : null
        }`}
        onClick={() => {
          props.setSelectedCoin("BTC");
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
          >
            <path
              d="M15.248 26V22.5463C19.3419 22.3221 22.0784 21.2821 22.4435 17.394C22.7295 14.274 21.2691 12.8819 18.9519 12.3218L18.953 12.3197C20.3494 11.5992 21.2377 10.3198 21.0319 8.19108C20.7621 5.27908 18.3907 4.303 15.248 4.03108V0H12.804V3.92058C12.1691 3.92058 11.5191 3.93683 10.868 3.95308V0.00108333H8.42404V4.03325C7.52596 4.06033 6.47946 4.04733 3.52087 4.03325V6.65708C5.45029 6.62242 6.46212 6.49783 6.69396 7.74475V18.785C6.54662 19.7752 5.76121 19.6322 4.01271 19.6008L3.52087 22.529C7.97662 22.529 8.42404 22.5453 8.42404 22.5453V25.9989H10.868V22.594C11.5133 22.6073 12.1586 22.6128 12.804 22.6103V26H15.248ZM10.9168 6.8315C12.297 6.8315 16.614 6.39925 16.614 9.29608C16.614 12.064 12.297 11.7444 10.9168 11.7444V6.8315ZM10.9168 19.6148V14.2068C12.5667 14.2068 17.6205 13.7432 17.6205 16.9108C17.6205 19.9669 12.5667 19.6148 10.9168 19.6148Z"
              fill="#FF9800"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-start p-2 text-sm md:text-lg">
          <div className="font-bold ">BTC</div>
          <div>${parseFloat(props?.coinsData[0]?.priceUsd)?.toFixed(2)}</div>
        </div>
      </div>

      {/* bnb */}
      <div
        className={`flex items-center  cursor-pointer ${
          props?.selectedCoin === "BNB" ? activeCoin : null
        }`}
        onClick={() => {
          props.setSelectedCoin("BNB");
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
          >
            <path
              d="M21.3456 14.4984L24.2869 11.557L27.2283 14.4984L24.2869 17.4397L21.3456 14.4984ZM1.77356 14.5L4.7148 11.5588L7.65603 14.5L4.7148 17.4412L1.77356 14.5ZM14.5 21.3467L9.62441 16.4711L6.68362 19.4119L14.5 27.2283L22.3164 19.4119L19.3757 16.4711L14.5 21.3467ZM14.5 7.65329L19.3757 12.5289L22.3164 9.58813L14.5 1.77173L6.68362 9.58813L9.62441 12.5289L14.5 7.65329Z"
              fill="#E6B816"
            />
            <path
              d="M11.5939 14.5L14.4984 11.5955L17.4029 14.5L14.4984 17.4045L11.5939 14.5Z"
              fill="#E6B816"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-start p-2 text-sm md:text-lg">
          <div className="font-bold">BNB</div>
          <div>${parseFloat(props?.coinsData[2]?.priceUsd)?.toFixed(2)}</div>
        </div>
      </div>

      {/* xpr */}
      <div
        className={`flex items-center  cursor-pointer ${
          props?.selectedCoin === "XRP" ? activeCoin : null
        }`}
        onClick={() => {
          props.setSelectedCoin("XRP");
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M25 1L21.118 1.05738L14.8932 7.80874C14.258 8.49615 13.3973 8.8822 12.5 8.8822C11.6027 8.8822 10.742 8.49615 10.1068 7.80874L3.882 1.05738L0 1L7.71362 9.36612C8.9837 10.7416 10.7052 11.5142 12.5 11.5142C14.2948 11.5142 16.0163 10.7416 17.2864 9.36612L25 1ZM0 24L3.882 23.9426L10.1068 17.1913C10.742 16.5039 11.6027 16.1178 12.5 16.1178C13.3973 16.1178 14.258 16.5039 14.8932 17.1913L21.118 23.9426L25 24L17.2864 15.6339C16.0163 14.2584 14.2948 13.4858 12.5 13.4858C10.7052 13.4858 8.9837 14.2584 7.71362 15.6339L0 24Z"
              fill="black"
            />
          </svg>
        </div>
        <div className="flex flex-col justify-center items-start p-2 text-sm md:text-lg">
          <div className="font-bold">XPR</div>
          <div>${parseFloat(props?.coinsData[3]?.priceUsd)?.toFixed(2)}</div>
        </div>
      </div>

      {/* sol */}
      <div
        className={`flex items-center  cursor-pointer ${
          props?.selectedCoin === "SOL" ? activeCoin : null
        }`}
        onClick={() => {
          props.setSelectedCoin("SOL");
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="23"
            viewBox="0 0 29 23"
            fill="none"
          >
            <g clip-path="url(#clip0_25_5222)">
              <path
                d="M23.1446 5.8341H0L5.85544 0H29L23.1446 5.8341ZM23.1446 24H0L5.85544 18.1688H29M5.85544 14.917H29L23.1446 9.08295H0"
                fill="#7C61C9"
              />
            </g>
            <defs>
              <clipPath id="clip0_25_5222">
                <rect width="29" height="23" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className="flex flex-col justify-center items-start p-2 text-sm md:text-lg">
          <div className="font-bold">SOL</div>
          <div>${parseFloat(props?.coinsData[4]?.priceUsd)?.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
}
