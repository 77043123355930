import { Col, Container, Row } from "reactstrap";
import CustomNavbar from "../../components/CustomNavbar";
import EmailPanel from "../../components/SuperAdmin/MassEmail/EmailPanel";
import UserList from "../../components/SuperAdmin/UserList";
import useSuperAdminAuth from "../../hooks/useSuperAdminAuth";

const MassEmail = () => {
  useSuperAdminAuth();

  return (
    <>
      <CustomNavbar
        navbarLinks={[
          {
            text: "User",
            url: "/superadmin/dashboard",
          },
          {
            text: "Broadcast Messaging",
            url: "/superadmin/massemail",
          },
          {
            text: "Bot",
            url: "/superadmin/bot",
          },
        ]}
      />
      <Container fluid className=" flex justify-center">
        <Row className="w-full justify-content-center p-0">
          <Col xs="12" className="p-0">
            <Row className="justify-content-center mt-5 w-full">
              <h2 style={{ textAlign: "center" }}>Broadcast Messaging</h2>
            </Row>
            <EmailPanel />
            <UserList showSelection />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default MassEmail;
