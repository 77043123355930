import axios from "axios";
import { BASE_URL } from "../config/urls";

const axiosObj = axios.create({
  baseURL: BASE_URL + "/public",
});

const getPublicStats = () => axiosObj.get("/stats");

const publicApi = {
  getPublicStats,
};

export default publicApi;
