import { useDispatch, useSelector } from "react-redux";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Media,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { logout } from "../redux/authSlice";
import dashboardIcon from "../assets/dashboard.svg";
import spotIcon from "../assets/spot.svg";
import futureIcon from "../assets/future.svg";
import affiliateIcon from "../assets/affiliate.svg";
import menuIcon from "../assets/menuIcon.svg";
import React from "react";

const CustomNavbar = ({ navbarLinks }) => {
  const { email } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [toggle, setToggle] = React.useState(true);

  return (
    <Navbar className="bg-[#2E273A] font-montserrat" dark expand="md">
      <div className="flex  w-full items-center gap-5">
        <div
          className="flex md:hidden"
          onClick={() => {
            setToggle(!toggle);
          }}
        >
          <img src={menuIcon} />
        </div>
        <NavbarBrand
          style={{
            fontWeight: "bold",
            letterSpacing: "4px",
            fontFamily: "sans-serif",
          }}
          href="/dashboard"
        >
          <div className="flex items-center w-full ml-0 md:ml-5">
            <a href="#" className="w-full flex items-center justify-center">
              <img
                alt="Logo"
                className="h-13 max-w-[35px]"
                src="/new-logo-white.png"
              />
              <img
                alt="Logo"
                className="ml-2 h-10 max-w-[120px]"
                src="/text-logo-white.svg"
              />
            </a>
          </div>
        </NavbarBrand>
      </div>
      {toggle && (
        <Nav
          className="w-full flex justify-between items-center mr-0 md:mr-10"
          navbar
        >
          {navbarLinks &&
            navbarLinks.map((link, index) => (
              <NavItem
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                className={
                  window.location.href.includes(link.url) &&
                  "bg-[#1D1728] rounded-md"
                }
                onClick={() => {
                  navigate(link.url, { replace: true });
                  // window.location.reload();
                }}
                key={index}
              >
                <NavLink
                  className="flex items-center justify-center"
                  // href={link.url}
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: "16px",
                    cursor: "pointer",
                    margin: "auto 1rem",
                  }}
                >
                  {link.url === "/dashboard" ? (
                    <img src={dashboardIcon} className="mr-2" />
                  ) : null}
                  {link.url === "/spot" ? (
                    <img src={spotIcon} className="mr-2" />
                  ) : null}
                  {link.url === "/futures" ? (
                    <img src={futureIcon} className="mr-2" />
                  ) : null}
                  {link.url === "/affiliate" ? (
                    <img src={affiliateIcon} className="mr-2" />
                  ) : null}
                  {link.text}
                </NavLink>
              </NavItem>
            ))}
        </Nav>
      )}

      {/* <NavbarText>
        <div className="d-flex align-items-center">
          <div className="mr-2">{email}</div>
          <div
            className="rounded-circle bg-white text-black text-center ml-5"
            style={{ width: "40px", height: "40px", lineHeight: "40px" }}
          >
            {email[0].toUpperCase()}
          </div>
        </div>
      </NavbarText> */}

      <Nav className="align-items-center d-none d-md-flex" navbar>
        <UncontrolledDropdown nav>
          <DropdownToggle className="pr-0" nav>
            <div
              className="rounded-circle bg-gray-700 text-white text-center ml-5"
              style={{ width: "40px", height: "40px", lineHeight: "40px" }}
            >
              {email && email[0].toUpperCase()}
            </div>
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" end>
            <DropdownItem className="noti-title" header tag="div">
              <h6 className="text-overflow m-0">{email}</h6>
            </DropdownItem>

            <DropdownItem divider />
            <DropdownItem
              href="#logout"
              onClick={(e) => {
                e.preventDefault();
                dispatch(logout());
                navigate("/login", { replace: true });
              }}
            >
              <i className="ni ni-user-run" />
              <span>Logout</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};

export default CustomNavbar;
