import { Button, Col, Input, Label, Row, Spinner } from "reactstrap";
import Panel from "../../BasicUIElements/Panel";
import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import superAdminApi from "../../../apis/superadmin-apis";

const EmailPanel = () => {
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [allUserSelected, setAllUserSelected] = useState(true);
  const [loading, setLoading] = useState(false);

  const { authToken } = useSelector((state) => state.auth);
  const { userList, selectedUser } = useSelector((state) => state.userList);

  const sendEmail = () => {
    setLoading(true);
    if (emailSubject.trim() !== "" && emailBody.trim() !== "") {
      var emailList = [];
      if (allUserSelected) {
        emailList = userList;
      } else {
        emailList = selectedUser;
      }

      superAdminApi
        .sendMassEmails({ emailList, emailBody, emailSubject }, authToken)
        .then((response) => {
          toast.success("Email Sent!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Email Send Encountered an Error!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setLoading(false);
        });
    } else {
      toast.error("Email Subject and Body required!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  return (
    <>
      <Row className="w-full flex justify-center">
        <Col xs="11" md="6" className="flex justify-center w-full">
          <div className="w-[90%] md:w-[50%]">
            <Row className="justify-content-center w-full mt-3 flex flex-col">
              <Col xs="3">
                <Label>Subject</Label>
              </Col>
              <Col xs="12">
                <Input
                  type="text"
                  value={emailSubject}
                  onChange={(e) => setEmailSubject(e.target.value)}
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3 flex flex-col">
              <Col xs="3">
                <Label>Email</Label>
              </Col>
              <Col xs="12">
                <Input
                  type="textarea"
                  value={emailBody}
                  onChange={(e) => setEmailBody(e.target.value)}
                  style={{ height: "180px" }}
                />
              </Col>
            </Row>
            <Row className="w-full justify-center mt-2">
              <Col>
                <Input
                  type="checkbox"
                  checked={allUserSelected}
                  onChange={(e) => setAllUserSelected(true)}
                />{" "}
                All Users
              </Col>
              <Col>
                <Input
                  type="checkbox"
                  checked={!allUserSelected}
                  onChange={() => setAllUserSelected(false)}
                />{" "}
                Selected Users
              </Col>
            </Row>
            <Row className="justify-content-end mt-4">
              <Col xs="12" md="12" className="flex justify-center w-full">
                <Button
                  className="bg-[#2e283b] w-fit px-4"
                  onClick={sendEmail}
                  disabled={loading}
                >
                  {loading ? <Spinner size={"sm"} /> : "Send"}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default EmailPanel;
