import welcomeAboardImg from "../../assets/WelcomeAboard.svg";

const Page0 = () => {
  return (
    <div className=" flex justify-center items-center ">
      <div className=" w-full md:w-[80%] flex ">
        <div className="flex flex-col md:flex-row px-1 md:px-5 sm:mt-0 md:mt-10 ">
          <div className=" w-full md:w-[60%]">
            <div className="flex md:hidden h-[50vh]  justify-center items-center">
              <img
                src={welcomeAboardImg}
                alt="welcomeAboardImg"
                className="w-full"
              />
            </div>
            <h5 className="mt-0 md:mt-5" style={{ fontSize: "35px" }}>
              Welcome Aboard!
            </h5>
            <p
              className=" text-justify p-1 md:p-3"
              style={{ fontSize: "19px" }}
            >
              With our platform, you can automate your crypto trading
              effortlessly, allowing you to seize opportunities in the
              fast-paced world of cryptocurrencies. Whether you're a seasoned
              trader or just starting your crypto journey, our user-friendly
              interface and powerful automated algorithms make it easy for you
              to stay ahead of the market trends. Say goodbye to sleepless
              nights monitoring price movements and hello to a smoother, more
              rewarding crypto trading experience. Let us be your trusted
              partner in navigating the exciting world of digital assets.
            </p>
          </div>
          <div className=" hidden md:flex h-[50vh]  justify-center items-center">
            <img
              src={welcomeAboardImg}
              alt="welcomeAboardImg"
              className="w-[80%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page0;
