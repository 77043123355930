import { Col, Container, Row } from "reactstrap";
import CustomNavbar from "../../components/CustomNavbar";
import useSuperAdminAuth from "../../hooks/useSuperAdminAuth";
import { useEffect } from "react";
import superAdminApi from "../../apis/superadmin-apis";
import { useSelector } from "react-redux";
// import StatsPanel from '../../components/SuperAdmin/UserEmailList/StatsPanel';
import UserList from "../../components/SuperAdmin/UserList";

const UserEmailList = () => {
  useSuperAdminAuth();

  const { authToken } = useSelector((state) => state.auth);

  useEffect(() => {
    superAdminApi
      .getUsersats(authToken)
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [authToken]);

  return (
    <>
      <CustomNavbar
        navbarLinks={[
          {
            text: "Users",
            url: "/superadmin/dashboard",
          },
          {
            text: "Broadcast Messaging",
            url: "/superadmin/massemail",
          },
          {
            text: "Bot",
            url: "/superadmin/bot",
          },
        ]}
      />
      <Container fluid>
        <Row>
          <Col xs="12">
            {/* <StatsPanel /> */}
            <UserList />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserEmailList;
