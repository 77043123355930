import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Spinner, Col } from "reactstrap";
// import {
//   getAuth,
//   createUserWithEmailAndPassword,
//   GoogleAuthProvider,
//   signInWithRedirect,
//   getRedirectResult,
// } from 'firebase/auth';
import { Label } from "reactstrap";
import { firebase } from "../../config/firebase";

import { useDispatch } from "react-redux";
import { setAuthToken, createUser, setAuthEmail } from "../../redux/authSlice";

import Alertbox from "../Dashboard/alertbox";

const SignupCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [authResponse, setAuthResponse] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [valError, setValError] = useState("");

  // const createUserInFirestore = useCallback(
  //   async (user) => {
  //     const { email, uid, emailVerified } = user;
  //     dispatch(createUser({ email, uid, emailVerified }));
  //   },
  //   [dispatch],
  // );

  const createUserInFirestore = async (user) => {
    const { email, uid, emailVerified } = user;
    dispatch(createUser({ email, uid, emailVerified }));
  };

  // const saveToken = useCallback(async () => {
  //   const auth = getAuth();
  //   var authToken = await auth.currentUser.getIdToken(true);
  //   dispatch(setAuthToken(authToken));
  // }, [dispatch]);

  const saveToken = async (authToken) => {
    // const auth = getAuth();
    // var authToken = await auth.currentUser.getIdToken(true);
    // var currentEmail = auth.currentUser.email;
    // console.log("current email: ", currentEmail);
    // // sessionStorage.setItem('authToken',authToken)
    dispatch(setAuthToken(authToken));
  };

  // useEffect(() => {
  //   const auth = getAuth();
  //   getRedirectResult(auth)
  //     .then(async (result) => {
  //       // This gives you a Google Access Token. You can use it to access Google APIs.

  //       // The signed-in user info.
  //       const user = result.user;
  //       console.log('SIGNED IN USER: ', user);
  //       await saveToken();
  //       await createUserInFirestore(user);
  //       navigate('/dashboard', { replace: true });
  //     })
  //     .catch((error) => {
  //       setPageLoading(false);
  //     });
  // }, [createUserInFirestore, navigate, saveToken]);

  const googlefirbaseLogin = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let response = await firebase
          .auth()
          .signInWithCredential(result.credential);

        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        // console.log(idTokenResult.token, idTokenResult.claims.email);
        // console.log(result);
        await saveToken(idTokenResult.token, idTokenResult.claims.email);

        await createUserInFirestore(result);
        navigate("/dashboard", { replace: true });
      })

      .catch((error) => {
        console.log("Fire Error: ", error);
        setPageLoading(false);
      });
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Name is required");
    } else {
      setNameError(null);
    }
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError(null);
    }
  };

  const handleSubmit = async (event) => {
    setAuthResponse("");
    event.preventDefault();
    validateEmail();
    validatePassword();
    if (!emailError && !passwordError) {
      await signupWithEmailPass();
    }
  };

  // const signupWithEmailPass = async () => {
  // setAuthResponse("WAIT");
  // const auth = getAuth();
  // createUserWithEmailAndPassword(
  //   auth,
  //   // name.trim(),
  //   email.trim(),
  //   password.trim()
  // )
  //   .then(async (userCredential) => {
  //     setAuthResponse("");
  //     await saveToken();
  //     dispatch(setAuthEmail(userCredential.user.email));
  //     await createUserInFirestore(userCredential.user);
  //     navigate("/dashboard", { replace: true });
  //   })
  //   .catch((error) => {
  //     console.log(error.message);
  //     setValError(error.message);
  //     if (error.code === "auth/email-already-in-use") {
  //       setAuthResponse("EXISTS");
  //     } else {
  //       setAuthResponse("FAILED");
  //     }
  //   });
  // };

  const signupWithEmailPass = async () => {
    setAuthResponse("WAIT");
    // const auth = getAuth();
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email.trim(), password.trim())
      .then(async (userCredential) => {
        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();

        setAuthResponse("");

        console.log(userCredential);
        console.log(idTokenResult);
        await saveToken(idTokenResult.token, idTokenResult.claims.email);
        dispatch(setAuthEmail(userCredential.user.email));
        await createUserInFirestore(userCredential.user);
        navigate("/dashboard", { replace: true });
      })
      .catch((error) => {
        console.log(error.message);
        setValError(error.message);
        if (error.code === "auth/email-already-in-use") {
          setAuthResponse("EXISTS");
        } else {
          setAuthResponse("FAILED");
        }
      });
  };

  const signupWithGoogle = async () => {
    // const provider = new GoogleAuthProvider();
    // const auth = getAuth();
    // signInWithRedirect(auth, provider);
    googlefirbaseLogin();
  };

  return pageLoading === true ? (
    <div className="w-full h-full flex justify-center items-center">
      <Spinner />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center font-montserrat mb-5">
      <div className="justify-content-center">
        {/* <Col xs="2">&nbsp;</Col> */}
        <Col className="d-flex justify-content-center">
          <button
            onClick={signupWithGoogle}
            className="mb-3 w-full bg-white  px-3  py-2 font-montserrat  outline-none border-gray-600  border-b border-r rounded-md shadow-none hover:bg-slate-300 text-lg"
          >
            <img
              src="./google-icon.png"
              className="mb-1 mr-1"
              width={20}
              height={20}
              alt="google"
            />{" "}
            Continue with Google
          </button>
        </Col>
      </div>
      <div className="flex w-[50%] items-center justify-center mt-4 ">
        <div className=" w-full border border-gray-500 flex-gdiv"></div>
        <div className="mx-4 text-gray-500 font-montserrat">OR</div>
        <div className=" w-full border border-gray-500 flex-gdiv"></div>
      </div>

      <Form
        onSubmit={handleSubmit}
        className=" w-[50%] justify-center flex flex-col items-center"
      >
        {valError && (
          <Alertbox
            title=""
            value={
              valError.includes("weak-password")
                ? "Password should be at least 6 characters"
                : valError === "Firebase: Error (auth/email-already-in-use)."
                ? "Email already used."
                : valError
            }
            link={false}
            type="error"
          />
        )}
        <div className="w-full mt-1">
          <div className=" flex flex-col w-full  mt-2 ">
            <div className="">
              <Label className="font-montserrat" for="text ">
                Full Name
              </Label>
            </div>
            <div className="w-full">
              <input
                type="text"
                className="w-full rounded-md border-gray-300 h-10 p-2 border-t border-l font-montserrat"
                value={name}
                onChange={(event) => setName(event.target.value)}
                onBlur={validateName}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {nameError && (
              <div className="text-danger text-align-center">{nameError}</div>
            )}
          </div>
        </div>

        <div className="w-full">
          <div className=" flex flex-col w-full  mt-4 ">
            <div className="">
              <Label className="font-montserrat" for="email">
                Email
              </Label>
            </div>
            <div className="w-full">
              <input
                type="email"
                className="w-full rounded-md border-gray-300 h-10 p-2 border-t border-l font-montserrat"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={validateEmail}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {emailError && (
              <div className="text-danger text-align-center">{emailError}</div>
            )}
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="flex flex-col w-full ">
            <div>
              <Label className="font-montserrat" for="password">
                Password
              </Label>
            </div>
            <div>
              <input
                type="password"
                name="password"
                id="password"
                className="w-full rounded-md border-gray-300 h-10 p-2 text-[20px] border-t border-l font-montserrat"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={validatePassword}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {passwordError && (
              <div className="text-danger text-align-center">
                {passwordError}
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex justify-center mt-5">
          {/* <Col xs="1">&nbsp;</Col> */}
          <div className="w-full justify-content-center">
            {authResponse === "WAIT" ? (
              <button type="submit" color="" className="" disabled={true}>
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                className=" bg-[#362F43] p-2 w-full text-lg  hover:bg-[#24202d] text-white  rounded-md"
              >
                <span className=" w-full "> Create Account</span>
              </button>
            )}
          </div>
        </div>
        <div className="mt-5 font-montserrat text-sm">
          Don't have an account?{" "}
          <span
            className=" font-bold cursor-pointer"
            onClick={() => {
              navigate("/login", { replace: true });
            }}
          >
            {" "}
            Login Account
          </span>
        </div>
      </Form>

      {/* <div className="d-flex justify-content-center">
                <Button onClick={signInWithGoogle} className='btn-google-signin'><img src='./google-icon.png' width={17} height={17} alt='google'/> Google</Button>
            </div> */}
    </div>
  );
};

export default SignupCard;
