import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, FormGroup, Alert, Spinner, div, Col } from "reactstrap";
// import {
//   getAuth,
//   signInWithEmailAndPassword,
//   GoogleAuthProvider,
//   signInWithRedirect,
//   getRedirectResult,
// } from "firebase/auth";
import { Label } from "reactstrap";

// import { doc, setDoc, getDoc } from "firebase/firestore";

import { firebase } from "../../config/firebase";
import { useDispatch } from "react-redux";
import { setAuthToken, createUser, setAuthEmail } from "../../redux/authSlice";
import axios from "axios";
import { BASE_URL } from "../../config/urls";
import { async } from "q";

const LoginCard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [authResponse, setAuthResponse] = useState("");
  const [pageLoading, setPageLoading] = useState(false);

  // useEffect(() => {
  //   const auth = getAuth();
  //   getRedirectResult(auth)
  //     .then(async (result) => {
  //       // This gives you a Google Access Token. You can use it to access Google APIs.
  //       const credential = GoogleAuthProvider.credentialFromResult(result);
  //       const token = credential.accessToken;

  //       // The signed-in user info.
  //       console.log(result);
  //       const user = result.user;
  //       await saveToken();
  //       await createUserInFirestore(user);
  //       navigate("/dashboard", { replace: true });

  //       //TODO: If new user new create a users doc in db.

  //       // IdP data available using getAdditionalUserInfo(result)
  //       // ...
  //     })
  //     .catch((error) => {
  //       setPageLoading(false);
  //       // Handle Errors here.
  //       console.log("google sign in error: ", error);
  //     });
  // }, []);

  // useEffect(() => {
  //   googlefirbaseLogin();
  // }, []);

  const googlefirbaseLogin = async () => {
    var provider = new firebase.auth.GoogleAuthProvider();

    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let response = await firebase
          .auth()
          .signInWithCredential(result.credential);

        const idTokenResult = await firebase
          .auth()
          .currentUser.getIdTokenResult();
        // console.log(idTokenResult.token, idTokenResult.claims.email);
        // console.log(result);
        await saveToken(idTokenResult.token, idTokenResult.claims.email);
        await createUserInFirestore(result);
        navigate("/dashboard", { replace: true });
      })

      .catch((error) => {
        console.log("Fire Error: ", error);
      });
  };

  const validateEmail = () => {
    if (email.trim() === "") {
      setEmailError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email address");
    } else {
      setEmailError(null);
    }
  };

  const validateName = () => {
    if (name.trim() === "") {
      setNameError("Name is required");
    } else {
      setNameError(null);
    }
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      setPasswordError("Password is required");
    } else {
      setPasswordError(null);
    }
  };

  const handleSubmit = async (event) => {
    console.log("submit called");
    event.preventDefault();
    validateName();
    validateEmail();
    validatePassword();
    if (!emailError && !passwordError && !nameError) {
      await signInWithEmailPass();
    }
  };

  // const signInWithEmailPass = async () => {
  //   // setAuthResponse("WAIT");
  //   // const auth = getAuth();
  //   // signInWithEmailAndPassword(auth, email.trim(), password.trim())
  //   //   .then(async (res) => {
  //   //     // setAuthResponse("");
  //   //     await saveToken();
  //   //     const auth = getAuth();
  //   //     var authToken = await auth.currentUser.getIdToken(true);
  //   //     await axios
  //   //       .get(BASE_URL + "/api/superadmin/auth", {
  //   //         headers: {
  //   //           AuthToken: authToken,
  //   //         },
  //   //       })
  //   //       .then(() => {
  //   //         navigate("/superadmin/dashboard", { replace: true });
  //   //       })
  //   //       .catch((err) => {
  //   //         navigate("/dashboard", { replace: true });
  //   //       });
  //   //   })
  //   //   .catch((err) => {
  //   //     console.log("Wrong Email or Password!");
  //   //     setAuthResponse("FAILED");
  //   //     console.log(err);
  //   //   });
  // };

  const signInWithEmailPass = async () => {
    // console.log(emailRef.current?.value);
    // console.log(passwordRef.current?.value);
    try {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email.trim(), password.trim());
      const idTokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult();

      // getlogin(idTokenResult.token);
      await saveToken(idTokenResult.token, idTokenResult.claims.email);

      await axios
        .get(BASE_URL + "/api/superadmin/auth", {
          headers: {
            AuthToken: idTokenResult.token,
          },
        })
        .then(() => {
          navigate("/superadmin/dashboard", { replace: true });
        })
        .catch((err) => {
          navigate("/dashboard", { replace: true });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const signInWithGoogle = async () => {
    // const provider = new GoogleAuthProvider();
    // const auth = getAuth();
    // signInWithRedirect(auth, provider);
    googlefirbaseLogin();
  };

  const createUserInFirestore = async (user) => {
    const { email, uid, emailVerified } = user;
    dispatch(createUser({ email, uid, emailVerified }));
  };

  const saveToken = async (authToken, email) => {
    // const auth = getAuth();
    // var authToken = await auth.currentUser.getIdToken(true);
    // var currentEmail = auth.currentUser.email;
    // console.log("current email: ", currentEmail);
    // // sessionStorage.setItem('authToken',authToken)
    dispatch(setAuthToken(authToken));
    dispatch(setAuthEmail(email));
  };

  return pageLoading === true ? (
    <div className="w-full h-full flex justify-center items-center">
      <Spinner />
    </div>
  ) : (
    <div className="flex flex-col items-center justify-center font-montserrat  mb-5">
      {authResponse === "FAILED" ? (
        <Alert color="danger">Wrong Email or Password! Try again</Alert>
      ) : null}
      <div className="justify-content-center">
        {/* <Col xs="2">&nbsp;</Col> */}
        <Col className="d-flex justify-content-center">
          <button
            onClick={signInWithGoogle}
            className="mb-3 w-full bg-white  px-3  py-2 font-montserrat  outline-none border-gray-300 border-b border-r rounded-md shadow-none hover:bg-slate-300 text-lg"
          >
            <img
              src="./google-icon.png"
              className="mb-1 mr-1"
              width={20}
              height={20}
              alt="google"
            />{" "}
            Continue with Google
          </button>
        </Col>
      </div>
      <div class="flex w-[50%] items-center justify-center mt-4 ">
        <div class=" w-full border border-gray-500 flex-gdiv"></div>
        <div class="mx-4 text-gray-500 font-montserrat">OR</div>
        <div class=" w-full border border-gray-500 flex-gdiv"></div>
      </div>

      <Form
        onSubmit={handleSubmit}
        className=" w-[50%] justify-center flex flex-col items-center"
      >
        {/* <div className="w-full">
          <div className=" flex flex-col w-full  mt-2 ">
            <div className="">
              <Label className="font-montserrat" for="text ">
                Full Name
              </Label>
            </div>
            <div className="w-full">
              <input
                type="text"
                className="w-full rounded-md border-gray-300 h-10 p-2 border-t border-l font-montserrat"
                value={name}
                onChange={(event) => setName(event.target.value)}
                onBlur={validateName}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {nameError && (
              <div className="text-danger text-align-center">{nameError}</div>
            )}
          </div>
        </div> */}

        <div className="w-full">
          <div className=" flex flex-col w-full  mt-4 ">
            <div className="">
              <Label className="font-montserrat" for="email">
                Email
              </Label>
            </div>
            <div className="w-full">
              <input
                type="email"
                className="w-full rounded-md border-gray-300 h-10 p-2 border-t border-l font-montserrat"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                onBlur={validateEmail}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {emailError && (
              <div className="text-danger text-align-center">{emailError}</div>
            )}
          </div>
        </div>

        <div className="w-full mt-4">
          <div className="flex flex-col w-full ">
            <div>
              <Label className="font-montserrat" for="password">
                Password
              </Label>
            </div>
            <div>
              <input
                type="password"
                name="password"
                id="password"
                className="w-full rounded-md border-gray-300 h-10 p-2 text-[20px] border-t border-l font-montserrat"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                onBlur={validatePassword}
              />
            </div>
          </div>
          <div className="justify-content-center full-width">
            {passwordError && (
              <div className="text-danger text-align-center">
                {passwordError}
              </div>
            )}
          </div>
        </div>

        <div className="w-full flex justify-center mt-5">
          {/* <Col xs="1">&nbsp;</Col> */}
          <div className="w-full justify-content-center">
            {authResponse === "WAIT" ? (
              <button type="submit" color="" className="" disabled={true}>
                <Spinner />
              </button>
            ) : (
              <button
                type="submit"
                className=" bg-[#362F43] p-2 w-full text-lg  hover:bg-[#24202d] text-white  rounded-md "
              >
                <span className=" w-full "> Sign In</span>
              </button>
            )}
          </div>
        </div>
        <div className="mt-5 font-montserrat text-sm">
          Don't have an account?{" "}
          <span
            className=" font-bold cursor-pointer"
            onClick={() => {
              navigate("/signup", { replace: true });
            }}
          >
            {" "}
            Create Account
          </span>
        </div>
      </Form>

      {/* <div className="d-flex justify-content-center">
                <Button onClick={signInWithGoogle} className='btn-google-signin'><img src='./google-icon.png' width={17} height={17} alt='google'/> Google</Button>
            </div> */}
    </div>
  );
};

export default LoginCard;
