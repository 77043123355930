import React from "react";
import { useNavigate } from "react-router-dom";

export default function AffiliateAlert() {
  const navigate = useNavigate();

  return (
    <div className="border p-3 flex flex-col items-start justify-center font-montserrat w-full mt-5">
      <div className="  text-sm md:text-lg">Become an Affiliate</div>
      <div className="text-[#1572A6] text-lg md:text-xl font-semibold">
        Activate Your Affiliate Account{" "}
      </div>

      <div>Earn 5% Flat Profit </div>
      <div
        className="flex w-full justify-end  text-[#E37131] underline cursor-pointer"
        onClick={() => {
          navigate("/affiliate", { replace: true });
        }}
      >
        Become Affiliate
      </div>
    </div>
  );
}
