import axios from "axios";
import { SERVER_API_URL } from "../config/urls";

const axiosObj = axios.create({
  baseURL: SERVER_API_URL,
});

const apiPermission = (payload, authToken) =>
  axiosObj.post(`/apipermission`, payload, {
    headers: { AuthToken: authToken },
  });

const apiKeysExist = (authToken) =>
  axiosObj.get(`/apikeysexist`, { headers: { AuthToken: authToken } });

const saveApi = (payload, authToken) =>
  axiosObj.post(`/saveapi`, payload, { headers: { AuthToken: authToken } });

const binanceAccountExists = (payload, authToken) =>
  axiosObj.post(`/binanceaccountexists`, payload, {
    headers: { AuthToken: authToken },
  });

const saveBinanceAlias = (payload, authToken) =>
  axiosObj.post(`/savebinancealias`, payload, {
    headers: { AuthToken: authToken },
  });

const getBrokersForUser = (authToken) =>
  axiosObj.get(`/userbrokers`, { headers: { AuthToken: authToken } });

const createBot = (payload, authToken) =>
  axiosObj.post(`/createbot`, payload, { headers: { AuthToken: authToken } });

const getBotById = (botId, authToken) =>
  axiosObj.get(`/bot/${botId}`, { headers: { AuthToken: authToken } });

const getAllBots = (authToken) =>
  axiosObj.get(`/bots`, { headers: { AuthToken: authToken } });

const createUser = (payload, authToken) =>
  axiosObj.post(`/createuser`, payload, { headers: { AuthToken: authToken } });

const userExists = (authToken) =>
  axiosObj.get(`/userexists`, { headers: { AuthToken: authToken } });

const getUser = (authToken) =>
  axiosObj.get(`/user`, { headers: { AuthToken: authToken } });

const setFirstTimeFalse = (authToken) =>
  axiosObj.post(
    `/setfirsttimefalse`,
    {},
    { headers: { AuthToken: authToken } }
  );

const updateSpotBot = (payload, authToken) =>
  axiosObj.put(`/spotbot`, payload, { headers: { AuthToken: authToken } });

const updateFuturesBot = (payload, authToken) =>
  axiosObj.put(`/futuresbot`, payload, { headers: { AuthToken: authToken } });

const getPnl = (authToken) =>
  axiosObj.get(`/getpnl`, { headers: { AuthToken: authToken } });

const api = {
  createUser,
  userExists,
  getUser,
  saveBinanceAlias,
  setFirstTimeFalse,
  apiPermission,
  saveApi,
  apiKeysExist,
  binanceAccountExists,
  getBrokersForUser,
  getBotById,
  getAllBots,
  createBot,
  updateSpotBot,
  updateFuturesBot,
  getPnl,
};
export default api;
