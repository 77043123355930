import { useEffect, useState } from 'react';
import useAuth from '../hooks/useAuth';
import api from '../apis';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

import InputAPIKeys from '../components/UserPage/InputAPIKeys';

const UserPage = () => {
  useAuth();
  const { authToken } = useSelector((state) => state.auth);

  const [loading, setLoading] = useState(true);
  const [showApiKeys, setShowApiKeys] = useState(false);

  useEffect(() => {
    // check first if the user has already added api keys. If api keys added then show Bot Status otherwise Binance Keys component.

    api
      .apiKeysExist(authToken)
      .then((response) => {
        console.log(response);
        if (response.data.exists === true) {
          console.log('not Show');
          setShowApiKeys(false);
        } else {
          console.log('Show');
          setShowApiKeys(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [authToken]);

  return (
    <div className="d-flex">
      {loading ? <Spinner /> : <>{showApiKeys && <InputAPIKeys />}</>}
    </div>
  );
};

export default UserPage;
