import { Col, Container, Row } from "reactstrap";
import CustomNavbar from "../components/CustomNavbar";
import Box from "../components/BasicUIElements/Box";
import Footer from "../components/Footer";

const FAQPage = () => {
  return (
    <>
      <CustomNavbar
        navbarLinks={[
          {
            text: "FAQ",
            url: "/faq",
          },
          {
            text: "Affiliate",
            url: "/affiliate",
          },
          {
            text: "About Us",
            url: "/aboutus",
          },
        ]}
      />
      <Container fluid>
        <Row className="justify-content-center mt-5">
          <Col
            xs="12"
            className="d-flex justify-content-center mt-5"
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <h1>FAQ</h1>
            <Row className="d-flex justify-content-center mt-5">
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 1: How do your advanced algorithms work?
                  </h3>

                  <p className="mt-5 mb-5">
                    Our advanced algorithms employ intricate data analysis to
                    identify market trends and patterns. These algorithms make
                    informed trading decisions in real-time, optimizing your
                    investments for maximum profitability. By adapting to
                    changing market conditions, they navigate the complexities
                    of cryptocurrency trading with precision.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 2: How can I trust the algorithmic trading system?
                  </h3>
                  <p className="mt-5 mb-5">
                    We prioritize transparency and accountability. Our
                    algorithms' actions are based on predefined strategies and
                    mathematical models, eliminating human bias. Additionally,
                    we provide real-time updates on trading activities, giving
                    you visibility into each decision made by the algorithm.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 3: Will I need to constantly monitor the trading
                    process?
                  </h3>
                  <p className="mt-5 mb-5">
                    No, our system is designed to provide a hands-free
                    experience. Once you set up your preferences and investment
                    parameters, our algorithms take care of the trading process.
                    You can trust that your investments are actively managed
                    without the need for constant monitoring.
                  </p>
                </Box>
              </Col>
            </Row>

            <Row className="d-flex justify-content-center mt-5">
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 4: What is the risk associated with algorithmic trading?
                  </h3>
                  <p className="mt-5 mb-5">
                    While algorithmic trading reduces emotional decision-making,
                    there is inherent risk in any investment. Our algorithms are
                    designed to mitigate risk by utilizing historical data,
                    predictive analytics, and risk management strategies.
                    However, it's important to understand that all investments
                    carry some level of risk.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 5: Can I customize the trading strategies used by the
                    algorithms?
                  </h3>
                  <p className="mt-5 mb-5">
                    Unlike traditional trading systems, our advanced algorithms
                    have been meticulously designed to provide a complete
                    hands-free user experience. They are built to execute
                    optimized trading strategies without requiring user
                    customization. This means you can enjoy the benefits of
                    algorithmic trading without the need to adjust or fine-tune
                    the strategies. Our goal is to simplify the investment
                    process for you while delivering consistent and profitable
                    results.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">
                    FAQ 6: How does the fee structure work?
                  </h3>
                  <p className="mt-5 mb-5">
                    We operate on a profit-sharing model. We take a 30% share of
                    the profits generated by the algorithms, and there are no
                    upfront costs. This means our success is tied to yours,
                    aligning our incentives to help you achieve maximum returns.
                  </p>
                </Box>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default FAQPage;
