import { Col, Row } from 'reactstrap';

const Panel = ({ children }) => {
  return (
    <Row>
      <Col
        xs="12"
        style={{
          textAlign: 'center',
          padding: '10px',
          borderRadius: '10px',
        }}
      >
        {children}
      </Col>
    </Row>
  );
};

export default Panel;
