import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import CustomNavbar from "../../components/CustomNavbar";
import { useEffect, useState, useCallback } from "react";
import superAdminApi from "../../apis/superadmin-apis";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Panel from "../../components/BasicUIElements/Panel";
import useSuperAdminAuth from "../../hooks/useSuperAdminAuth";
import publicApi from "../../apis/public-apis";
import UserList from "../../components/SuperAdmin/UserList";
const BotControl = () => {
  useSuperAdminAuth();

  const [loading, setLoading] = useState(false);
  const [statLoading, setStatLoading] = useState(false);
  const [symbol, setSymbol] = useState("");
  const [monthlyStat, setMonthlyStat] = useState(0);
  const [threeMonthStat, setThreeMonthStat] = useState(0);
  const { authToken } = useSelector((state) => state.auth);
  const [userList, setUserList] = useState([]);

  const addUser = useCallback((usernames) => {
    setUserList(usernames);
  }, []);

  useEffect(() => {
    publicApi.getPublicStats().then((response) => {
      console.log(response);
      setMonthlyStat(response.data.stats.monthly);
      setThreeMonthStat(response.data.stats.threemonth);
    });
  }, []);

  const sendManualBotCommand = (tradeSide) => {
    setLoading(true);

    if (symbol.trim() !== "") {
      superAdminApi
        .manualBotCommand(tradeSide, symbol, authToken, userList)
        .then((response) => {
          toast.success("Command Sent!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(response);
          setSymbol("");
          setLoading(false);
        })
        .catch((err) => {
          toast.error("Command Failed!", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          console.log(err);
          setLoading(false);
        });
    } else {
      toast.error("Symbol is Required!", {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setLoading(false);
    }
  };

  const updateStats = async () => {
    setStatLoading(true);
    superAdminApi
      .updateStats(
        { monthly: monthlyStat, threemonth: threeMonthStat },
        authToken
      )
      .then((res) => {
        setStatLoading(false);
      })
      .catch((err) => {
        setStatLoading(false);
      });
  };

  return (
    <>
      <CustomNavbar
        navbarLinks={[
          {
            text: "Users",
            url: "/superadmin/dashboard",
          },
          {
            text: "Broadcast Messaging",
            url: "/superadmin/massemail",
          },
          {
            text: "Bot",
            url: "/superadmin/bot",
          },
        ]}
      />
      <Container fluid>
        <Row className="w-full justify-center text-align-center mt-0 md:mt-5 p-0 ml-2 md:ml-0">
          <Col xs="11" md="8" className="p-0 ">
            <Panel>
              <div>
                <Input
                  type="text"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                  placeholder='Enter Symbol e.g."BTCUSDT"'
                />
              </div>
              <Row className="full-width  flex  gap-2 justify-content-center items-center mt-4 mb-0 md:mb-5 p-0">
                <Col className="d-flex justify-content-center p-0">
                  <Button
                    className="text-[14px] flex items-center"
                    disabled={
                      loading || symbol.trim() === "" || userList.length === 0
                    }
                    color="success"
                    onClick={() => sendManualBotCommand("BUY")}
                  >
                    Buy Order{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 64 64"
                      width="18"
                      height="18"
                      style={{ marginBottom: "3px", marginLeft: "5px" }}
                    >
                      <path
                        d="M63.73 13.32a.512.512 0 0 0-.52.05L31.8 36.93.79 13.37a.512.512 0 0 0-.52-.05c-.16.09-.27.26-.27.44v12.43c0 .15.07.3.19.39L31.7 50.63c.09.07.2.1.3.1.11 0 .21-.03.3-.1l31.51-24.32c.12-.09.19-.24.19-.39V13.76c0-.18-.1-.35-.27-.44z"
                        fill="#ffffff"
                        class="color000 svgShape"
                      ></path>
                    </svg>{" "}
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center p-0">
                  <Button
                    className="text-[14px] flex items-center"
                    disabled={
                      loading || symbol.trim() === "" || userList.length === 0
                    }
                    color="warning"
                    onClick={() => sendManualBotCommand("SELL")}
                  >
                    Sell Order{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 19 19"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1281_160)">
                        <path
                          d="M0.842597 14.4602C0.865971 14.4713 0.891888 14.4758 0.917626 14.4734C0.943364 14.471 0.967974 14.4617 0.988873 14.4464L9.83536 7.83679L18.5445 14.4794C18.5653 14.4947 18.5899 14.5041 18.6156 14.5066C18.6413 14.5091 18.6673 14.5047 18.6907 14.4937C18.7357 14.4685 18.7668 14.4208 18.7669 14.3701L18.7734 10.8742C18.7735 10.832 18.7539 10.7898 18.7202 10.7644L9.87072 3.98372C9.84545 3.96399 9.81453 3.95549 9.7864 3.95544C9.75546 3.95538 9.72732 3.96376 9.70197 3.9834L0.826957 10.8068C0.793159 10.832 0.773393 10.8742 0.773314 10.9163L0.766892 14.3363C0.766797 14.387 0.794832 14.4348 0.842597 14.4602Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1281_160">
                          <rect
                            width="18"
                            height="18"
                            fill="white"
                            transform="translate(18.7598 18.2402) rotate(-179.892)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </Button>
                </Col>
                <Col className="d-flex justify-content-center p-0">
                  <Button
                    disabled={
                      loading || symbol.trim() === "" || userList.length === 0
                    }
                    onClick={() => sendManualBotCommand("CLOSE")}
                    color="danger"
                  >
                    Close{" "}
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.02851 15.7218C5.31602 15.7218 2.30664 12.7125 2.30664 8.99995C2.30664 5.28745 5.31602 2.27808 9.02851 2.27808C12.741 2.27808 15.7504 5.25933 15.7504 8.97183C15.7504 12.6843 12.741 15.7218 9.02851 15.7218ZM9.02851 2.9812C5.73789 2.9812 3.03789 5.6812 3.03789 8.97183C3.03789 12.2625 5.73789 14.9625 9.02851 14.9625C12.3191 14.9625 15.0191 12.2625 15.0191 8.97183C15.0191 5.6812 12.3191 2.9812 9.02851 2.9812Z"
                        fill="#000000"
                      />
                      <path
                        d="M5.42871 8.57812H12.6006V9.36562H5.42871V8.57812Z"
                        fill="#000000"
                      />
                    </svg>
                  </Button>
                </Col>
              </Row>
            </Panel>
          </Col>
        </Row>

        <UserList addUser={addUser} />

        <div className=" flex flex-col md:flex-col mt-3 p-3 mb-5 items-center justify-center w-full">
          <div className="w-[90%] md:w-[50%]">
            <div>
              <InputGroup>
                <Row className="full-width">
                  <Label>Monthly Stat</Label>
                </Row>
                <Row className="full-width">
                  <Input
                    type="number"
                    value={monthlyStat}
                    onChange={(e) => setMonthlyStat(e.target.value)}
                  />
                </Row>
              </InputGroup>
            </div>
            <div>
              <InputGroup>
                <Row className="full-width">
                  <Label>Three Month Stat</Label>
                </Row>
                <Row className="full-width">
                  <Input
                    type="number"
                    value={threeMonthStat}
                    onChange={(e) => setThreeMonthStat(e.target.value)}
                  />
                </Row>
              </InputGroup>
            </div>

            <Row className="full-width justify-content-end mt-4">
              <Button
                className="bg-[#2e283b]"
                onClick={updateStats}
                disabled={statLoading}
                style={{ maxWidth: "80px" }}
              >
                {statLoading ? <Spinner size={"sm"} /> : "Update"}
              </Button>
            </Row>
          </div>
        </div>
      </Container>
    </>
  );
};

export default BotControl;
