import { Col } from 'reactstrap';

const AddBotCard = (props) => {
  return (
    <Col
      className="add-bot-card"
      sm="6"
      md="3"
      onClick={() => (window.location = '/createbot')}
    >
      <h3 className="bc-name">+</h3>
      <div className="bc-subtext">Create a New Bot</div>
    </Col>
  );
};

export default AddBotCard;
