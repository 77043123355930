import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProfitBoxes(props) {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col md:flex-row gap-3 items-center justify-between font-montserrat mt-5 w-full">
      <div
        className="border w-full  p-3 cursor-pointer"
        onClick={() => {
          navigate("/spot", { replace: true });
        }}
      >
        <div className="flex w-full justify-between">
          <div className="text-[#646464] text-sm md:text-[20px]">
            Spot Invested Amount
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <circle cx="14.5338" cy="15.0324" r="14.5" fill="#F1F1F1" />
              <path
                d="M21.7821 10.8224L20.4546 19.0951C20.416 19.2552 20.319 19.3898 20.1831 19.4719C20.0471 19.5541 19.8821 19.5777 19.7211 19.538C19.5601 19.4984 19.415 19.3984 19.3147 19.2581C19.2144 19.1179 19.1663 18.9476 19.1801 18.7813L20.2515 12.0747L7.84972 20.2915C7.71029 20.3839 7.53672 20.4123 7.3672 20.3706C7.19768 20.3289 7.04609 20.2204 6.94577 20.069C6.84546 19.9176 6.80465 19.7357 6.83231 19.5633C6.85997 19.3909 6.95384 19.2422 7.09327 19.1498L19.4951 10.933L12.9 9.31409C12.7416 9.26195 12.6035 9.15129 12.5134 9.00423C12.4233 8.85717 12.3879 8.68454 12.4141 8.52084C12.4404 8.35714 12.5265 8.21442 12.6551 8.12121C12.7837 8.028 12.9455 7.99116 13.108 8.01805L21.2436 10.021C21.4123 10.0631 21.5632 10.1714 21.6631 10.3222C21.763 10.473 21.8039 10.6541 21.7769 10.8259L21.7821 10.8224Z"
                fill="#474444"
              />
              <path
                d="M21.7821 10.8224L20.4546 19.0951C20.416 19.2552 20.319 19.3898 20.1831 19.4719C20.0471 19.5541 19.8821 19.5777 19.7211 19.538C19.5601 19.4984 19.415 19.3984 19.3147 19.2581C19.2144 19.1179 19.1663 18.9476 19.1801 18.7813L20.2515 12.0747L7.84972 20.2915C7.71029 20.3839 7.53672 20.4123 7.3672 20.3706C7.19768 20.3289 7.04609 20.2204 6.94577 20.069C6.84546 19.9176 6.80465 19.7357 6.83231 19.5633C6.85997 19.3909 6.95384 19.2422 7.09327 19.1498L19.4951 10.933L12.9 9.31409C12.7416 9.26195 12.6035 9.15129 12.5134 9.00423C12.4233 8.85717 12.3879 8.68454 12.4141 8.52084C12.4404 8.35714 12.5265 8.21442 12.6551 8.12121C12.7837 8.028 12.9455 7.99116 13.108 8.01805L21.2436 10.021C21.4123 10.0631 21.5632 10.1714 21.6631 10.3222C21.763 10.473 21.8039 10.6541 21.7769 10.8259L21.7821 10.8224Z"
                stroke="black"
              />
            </svg>
          </div>
        </div>

        <div className="  text-lg md:text-[30px] font-semibold text-[#5CA668]">
          ${props.spotDollarAmount ? props.spotDollarAmount : "0"}.00
        </div>

        <div className="flex justify-between">
          {/* <div className=" text-md text-[#646464]">in last 30 days</div>
          <div className=" text-sm text-[#5CA668]">+30%</div> */}
        </div>
      </div>

      <div
        className="border w-full  p-3 cursor-pointer"
        onClick={() => {
          navigate("/futures", { replace: true });
        }}
      >
        <div className="flex w-full justify-between">
          <div className="text-[#646464] text-sm md:text-[20px]">
            Futures Invested Amount{" "}
          </div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 30 30"
              fill="none"
            >
              <circle cx="14.5338" cy="15.0324" r="14.5" fill="#F1F1F1" />
              <path
                d="M21.7821 10.8224L20.4546 19.0951C20.416 19.2552 20.319 19.3898 20.1831 19.4719C20.0471 19.5541 19.8821 19.5777 19.7211 19.538C19.5601 19.4984 19.415 19.3984 19.3147 19.2581C19.2144 19.1179 19.1663 18.9476 19.1801 18.7813L20.2515 12.0747L7.84972 20.2915C7.71029 20.3839 7.53672 20.4123 7.3672 20.3706C7.19768 20.3289 7.04609 20.2204 6.94577 20.069C6.84546 19.9176 6.80465 19.7357 6.83231 19.5633C6.85997 19.3909 6.95384 19.2422 7.09327 19.1498L19.4951 10.933L12.9 9.31409C12.7416 9.26195 12.6035 9.15129 12.5134 9.00423C12.4233 8.85717 12.3879 8.68454 12.4141 8.52084C12.4404 8.35714 12.5265 8.21442 12.6551 8.12121C12.7837 8.028 12.9455 7.99116 13.108 8.01805L21.2436 10.021C21.4123 10.0631 21.5632 10.1714 21.6631 10.3222C21.763 10.473 21.8039 10.6541 21.7769 10.8259L21.7821 10.8224Z"
                fill="#474444"
              />
              <path
                d="M21.7821 10.8224L20.4546 19.0951C20.416 19.2552 20.319 19.3898 20.1831 19.4719C20.0471 19.5541 19.8821 19.5777 19.7211 19.538C19.5601 19.4984 19.415 19.3984 19.3147 19.2581C19.2144 19.1179 19.1663 18.9476 19.1801 18.7813L20.2515 12.0747L7.84972 20.2915C7.71029 20.3839 7.53672 20.4123 7.3672 20.3706C7.19768 20.3289 7.04609 20.2204 6.94577 20.069C6.84546 19.9176 6.80465 19.7357 6.83231 19.5633C6.85997 19.3909 6.95384 19.2422 7.09327 19.1498L19.4951 10.933L12.9 9.31409C12.7416 9.26195 12.6035 9.15129 12.5134 9.00423C12.4233 8.85717 12.3879 8.68454 12.4141 8.52084C12.4404 8.35714 12.5265 8.21442 12.6551 8.12121C12.7837 8.028 12.9455 7.99116 13.108 8.01805L21.2436 10.021C21.4123 10.0631 21.5632 10.1714 21.6631 10.3222C21.763 10.473 21.8039 10.6541 21.7769 10.8259L21.7821 10.8224Z"
                stroke="black"
              />
            </svg>
          </div>
        </div>

        <div className="font-semibold text-lg md:text-[30px] text-[#E4765E]">
          {" "}
          ${props.futuresDollarAmount ? props.futuresDollarAmount : "0"}.00
        </div>

        <div className="flex justify-between">
          {/* <div className=" text-md text-[#646464]">in last 30 days</div>
          <div className=" text-sm text-[#21A300]">+30%</div> */}
        </div>
      </div>
    </div>
  );
}
