import { Col, Input, Label, Row } from 'reactstrap';

import { useDispatch, useSelector } from 'react-redux';
import { setApiKey, setApiSecret } from '../../redux/binanceApiSlice';
import keys from '../../assets/keys.svg';

// import { Tooltip as ReactTooltip } from "react-tooltip";
// import ReactTooltip from "react-tooltip";

const BinanceAPIPage = () => {
  const dispatch = useDispatch();
  const { apiKey, apiSecret } = useSelector((state) => state.binanceApi);

  return (
    <Row className="mt-0 md:mt-5 flex w-full justify-center ">
      <Col
        xs="11"

        className="w-full  md:w-[80%]  mt-5 text-align-center font-montserrat font-bold text-start"
        style={{ flexDirection: "column" }}
      >
        <h2 className="font-bold text-lg md:text-[30px] ">
          Securely Connect your{" "}
          <span>
            <img
              src="./binance_logo.png"
              height="40px"
              alt="binance"
              className="w-[100px]  md:w-auto"
            />
          </span>{" "}

          account to Blockwave
        </h2>
        <h2 className=" text-[20px] text-start mt-3">
          Create your API Keys here and input them in the fields below. <br />
        </h2>
        <h2 className="text-[20px] text-start mt-3">
          <a
            href="https://www.blockwaveventures.com/api-integration"
            target="_blank"
            className="pt-4"
            rel="noreferrer"
          >
            <span className=" text-blue-900 no-underline underline-offset-2 mt-6  ">
              Click Here
            </span>
          </a>{' '}
          for complete guide on how to create API keys.
        </h2>
        <div className="w-full  bg-gray-100 flex items-center p-2 rounded-lg mt-5">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
            >
              <path
                d="M41.36 29.8833L27.2983 6.56334C26.7298 5.67384 25.9464 4.9418 25.0204 4.43473C24.0944 3.92765 23.0557 3.66187 22 3.66187C20.9443 3.66187 19.9056 3.92765 18.9796 4.43473C18.0537 4.9418 17.2703 5.67384 16.7017 6.56334L2.64002 29.8833C2.14329 30.7114 1.8733 31.6556 1.85721 32.6211C1.84112 33.5865 2.07949 34.5392 2.54835 35.3833C3.09043 36.3335 3.87506 37.1227 4.82206 37.6703C5.76905 38.2179 6.84445 38.5042 7.93835 38.5H36.0617C37.1484 38.5116 38.2189 38.2364 39.1653 37.702C40.1116 37.1677 40.9003 36.3932 41.4517 35.4567C41.9343 34.6038 42.1803 33.6374 42.1642 32.6575C42.1481 31.6777 41.8704 30.7199 41.36 29.8833ZM22 31.1667C21.6374 31.1667 21.283 31.0592 20.9815 30.8577C20.68 30.6563 20.445 30.3699 20.3062 30.0349C20.1675 29.6999 20.1312 29.3313 20.2019 28.9757C20.2726 28.62 20.4473 28.2934 20.7037 28.037C20.9601 27.7806 21.2867 27.606 21.6424 27.5352C21.998 27.4645 22.3666 27.5008 22.7016 27.6396C23.0366 27.7783 23.3229 28.0133 23.5244 28.3148C23.7258 28.6163 23.8333 28.9707 23.8333 29.3333C23.8333 29.8196 23.6402 30.2859 23.2964 30.6297C22.9526 30.9735 22.4862 31.1667 22 31.1667ZM23.8333 23.8333C23.8333 24.3196 23.6402 24.7859 23.2964 25.1297C22.9526 25.4735 22.4862 25.6667 22 25.6667C21.5138 25.6667 21.0475 25.4735 20.7037 25.1297C20.3598 24.7859 20.1667 24.3196 20.1667 23.8333V16.5C20.1667 16.0138 20.3598 15.5475 20.7037 15.2036C21.0475 14.8598 21.5138 14.6667 22 14.6667C22.4862 14.6667 22.9526 14.8598 23.2964 15.2036C23.6402 15.5475 23.8333 16.0138 23.8333 16.5V23.8333Z"
                fill="#EB9D55"
              />
            </svg>
          </div>

          <div className="ml-3 font-medium">
            Make sure to create API keys without restricting IPs, this you will
            do next.
          </div>
        </div>
        <Row
          className="flex  mt-5 sm:gap-3 md:gap-0"
          // style={{ marginTop: "100px" }}
        >
          <Col xs="12" md="6">
            <label
              className="text-lg font-medium"
              data-tip="Tooltip for the label"
            >
              Insert API key
            </label>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-3"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              id="info"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"></path>
            </svg>

            <Input
              type="text"
              value={apiKey}
              onChange={(e) => dispatch(setApiKey(e.target.value))}
            />
          </Col>
          <Col xs="12" md="6">
            <Label className="text-lg font-medium mb-0">
              Insert API Secret
            </Label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="ml-3"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              id="info"
            >
              <path fill="none" d="M0 0h24v24H0V0z"></path>
              <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1zm1-8h-2V7h2v2z"></path>
            </svg>

            <Input
              type="text"
              value={apiSecret}
              onChange={(e) => dispatch(setApiSecret(e.target.value))}
            />
          </Col>
        </Row>
        <div className="flex w-full justify-center md:justify-end">
          <img src={keys} alt="keys" />
        </div>
      </Col>
    </Row>
  );
};

export default BinanceAPIPage;
