import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../apis';

const binanceApiSlice = createSlice({
  name: 'binanceApi',
  initialState: {
    apiKey: '',
    apiSecret: '',
    apiCreationSuccess: false,
  },
  reducers: {
    setApiKey: (state, action) => {
      state.apiKey = action.payload;
    },
    setApiSecret: (state, action) => {
      state.apiSecret = action.payload;
    },
    setApiCreationSuccess: (state, action) => {
      state.apiCreationSuccess = action.payload;
    },
  },
});

export default binanceApiSlice.reducer;
export const { setApiKey, setApiSecret, setApiCreationSuccess } =
  binanceApiSlice.actions;

export const addBinanceAPI = () => async (dispatch, getState) => {
  const authToken = getState().auth.authToken;
  const { apiKey, apiSecret } = getState().binanceApi;

  if (apiKey.trim() !== '' && apiSecret.trim() !== '') {
    api
      .binanceAccountExists(
        {
          apiKeys: {
            apiKey: apiKey,
            apiSecret: apiSecret,
          },
        },
        authToken,
      )
      .then(async (response) => {
        console.log(response);
        if (response.data.binanceAccountAlreadyExists === false) {
          // new binance account

          await api.saveApi(
            {
              broker: 'BINANCE',
              apiKey,
              apiSecret,
            },
            authToken,
          );
          await api.saveBinanceAlias(
            {
              binanceAccountAlias: response.data.binanceAccountAlias,
            },
            authToken,
          );

          //success toast
          toast.success('Binance API Keys added!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          dispatch(setApiCreationSuccess(true));
        } else {
          //binance account already exists
          // duplicate toast error
          toast.error('Binance Account Already Exists!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          });
          dispatch(setApiCreationSuccess(false));
        }
      })
      .catch((err) => {
        console.log(err);
        if (err.response.status === 403) {
          toast.error(
            'Please Ensure that API is correct with Permissions and Whitelisted IP address!',
            {
              position: 'bottom-right',
              autoClose: 7000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            },
          );
        }
        dispatch(setApiCreationSuccess(false));
      });
  } else {
    // empty toast error
    toast.error('Please Enter API Keys to Proceed!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'light',
    });
    dispatch(setApiCreationSuccess(false));
  }
};
