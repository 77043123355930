import axios from 'axios';
import { BASE_URL } from '../config/urls';

const axiosObj = axios.create({
  baseURL: BASE_URL + '/api/superadmin',
});

const getAllUsers = (authToken) =>
  axiosObj.get('/users', { headers: { AuthToken: authToken } });

const getUsersats = (authToken) =>
  axiosObj.get('/Usersats', { headers: { AuthToken: authToken } });

const sendMassEmails = (payload, authToken) =>
  axiosObj.post('/massemail', payload, { headers: { AuthToken: authToken } });

const manualBotCommand = (tradeSide, symbol, authToken, userList) =>
  axiosObj.post('/manualbotcommand', {
    headers: { AuthToken: authToken },
    params: { tradeSide, symbol, userList },
  });

const getPnlForUser = (userId, authToken) =>
  axiosObj.get('/pnl', {
    headers: { AuthToken: authToken },
    params: { customerId: userId },
  });
const getUsersWithPnl = (authToken) =>
  axiosObj.get('/userswithpnl', { headers: { AuthToken: authToken } });

const updateStats = (payload, authToken) =>
  axiosObj.post('/stats', payload, { headers: { AuthToken: authToken } });

const superAdminApi = {
  getAllUsers,
  getUsersats,
  sendMassEmails,
  manualBotCommand,
  getPnlForUser,
  getUsersWithPnl,
  updateStats,
};
export default superAdminApi;
