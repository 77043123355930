import { Col, Container, Row } from "reactstrap";
import CustomNavbar from "../components/CustomNavbar";
import Box from "../components/BasicUIElements/Box";
import Footer from "../components/Footer";

const AboutUsPage = () => {
  return (
    <>
      <CustomNavbar
        navbarLinks={[
          {
            text: "FAQ",
            url: "/faq",
          },
          {
            text: "Affiliate",
            url: "/affiliate",
          },
          {
            text: "About Us",
            url: "/aboutus",
          },
        ]}
      />
      <Container fluid>
        <Row className="justify-content-center mt-5">
          <Col
            xs="12"
            className="d-flex justify-content-center mt-5"
            style={{ flexDirection: "column", textAlign: "center" }}
          >
            <h1>About Us</h1>
            <Row className="d-flex justify-content-center mt-5">
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">Mission and Values</h3>

                  <p className="mt-5 mb-5">
                    Our mission at Blockwave Ventures is to democratize crypto
                    trading and investment, making it accessible and profitable
                    for everyone. We believe in empowering individuals to
                    harness the potential of cryptocurrency markets, regardless
                    of their level of experience. Our values revolve around
                    integrity, transparency, and client success. We are
                    committed to providing a safe and reliable platform that
                    aligns with the best interests of our users.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">Transparency and Trust</h3>
                  <p className="mt-5 mb-5">
                    We understand that trust is paramount in the world of crypto
                    trading. That's why we prioritize transparency in everything
                    we do. When you invest with us, your funds remain securely
                    in your own Binance wallet throughout the entire investment
                    process. We never gain direct access to your assets,
                    maintaining the highest level of security. Our fee
                    structure, which involves a 30% share of profits without any
                    upfront costs, aligns our success with yours. We are
                    committed to open communication, and our platform provides
                    real-time updates on trading activities, ensuring you're
                    always aware of your investment's performance.
                  </p>
                </Box>
              </Col>
              <Col
                xs="11"
                md="3"
                className="m-5"
                style={{ minHeight: "350px" }}
              >
                <Box fullHeight>
                  <h3 className="mt-4">Algorithmic Advantage</h3>
                  <p className="mt-5 mb-5">
                    Our distinct edge lies in our advanced algorithms, setting
                    us apart from the competition. Our advanced algorithms have
                    been meticulously crafted to analyze intricate market
                    patterns, seize opportunities, and make informed trading
                    decisions in real-time. By leveraging these cutting-edge
                    algorithms, we unlock the potential for consistent profit in
                    the dynamic world of cryptocurrency trading. Our algorithms
                    dynamically adapt to market shifts, capturing trends and
                    mitigating risks, ensuring your investments are optimized
                    for growth.
                  </p>
                </Box>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default AboutUsPage;
