import {
  Button,
  Col,
  Input,
  InputGroup,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import { useEffect, useState } from 'react';
import api from '../../apis';
import { useSelector } from 'react-redux';

const SpotBot = () => {
  const { authToken } = useSelector((state) => state.auth);

  const [spotActive, setSpotActive] = useState(false);
  const [spotDollarAmount, setSpotDollarAmount] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    api
      .getUser(authToken)
      .then((res) => {
        if (res.data.user.spotIsActive) {
          setSpotActive(true);
        }
        setSpotDollarAmount(res.data.user.spotDollarAmount);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [authToken]);

  const startBot = async () => {
    setLoading(true);
    api
      .updateSpotBot({ spotDollarAmount, spotIsActive: true }, authToken)
      .then((response) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const stopBot = async () => {
    setLoading(true);
    api
      .updateSpotBot({ spotDollarAmount, spotIsActive: false }, authToken)
      .then((response) => {
        setLoading(false);
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Row className="d-flex font-montserrat">
        <Col
          xs="12"
          className="d-flex justify-content-center "
          style={{ flexDirection: 'column', textAlign: 'center' }}
        >
          <div className="bgcoverSpot"></div>

          {/* <h1 className=" font-montserrat flex w-full justify-start pl-10 items-center text-[30px]"> */}{" "}
          <div className=" w-full h-0 p-3  text-white relative font-montserrat text-[30px] -top-[100px] flex justify-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 50 50"
              fill="none"
              className="mr-2 flex items-center"
            >
              <g clip-path="url(#clip0_281_286)">
                <path
                  d="M49.9998 15.7895C49.9998 17.863 49.5914 19.9162 48.798 21.8318C48.0043 23.7475 46.8414 25.4881 45.3751 26.9542C43.909 28.4205 42.1685 29.5834 40.2527 30.3771C38.3369 31.1705 36.2838 31.5789 34.2104 31.5789C32.1369 31.5789 30.0838 31.1705 28.168 30.3771C26.2523 29.5834 24.5117 28.4205 23.0455 26.9542C21.5793 25.4881 20.4163 23.7475 19.6228 21.8318C18.8293 19.9162 18.4209 17.863 18.4209 15.7895C18.4209 11.6018 20.0844 7.58573 23.0455 4.62463C26.0066 1.66353 30.0227 0 34.2104 0C38.398 0 42.414 1.66353 45.3751 4.62463C48.3364 7.58573 49.9998 11.6018 49.9998 15.7895Z"
                  fill="#848484"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15.7895 18.4211C17.863 18.4211 19.9162 18.8295 21.8318 19.623C23.7475 20.4165 25.4881 21.5796 26.9542 23.0458C28.4205 24.512 29.5834 26.2526 30.3771 28.1682C31.1705 30.084 31.5789 32.1372 31.5789 34.2106C31.5789 36.284 31.1705 38.3372 30.3771 40.253C29.5834 42.1688 28.4205 43.9093 26.9542 45.3753C25.4881 46.8417 23.7475 48.0046 21.8318 48.7982C19.9162 49.5917 17.863 50.0001 15.7895 50.0001C11.6018 50.0001 7.58573 48.3367 4.62463 45.3753C1.66353 42.4143 0 38.3982 0 34.2106C0 30.023 1.66353 26.0069 4.62463 23.0458C7.58573 20.0847 11.6018 18.4211 15.7895 18.4211ZM15.7895 40.7896L9.21052 34.2106L15.7895 27.6317L22.3684 34.2106L15.7895 40.7896Z"
                  fill="white"
                />
                <path
                  d="M17.1054 1.31567H1.31592V17.1051L17.1054 1.31567Z"
                  fill="white"
                />
                <path
                  d="M32.8945 48.6842H48.684V32.8948L32.8945 48.6842Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_281_286">
                  <rect width="50" height="50" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Spot Bot
          </div>
          {/* </h1> */}

          {/* <h1>Spot Bot</h1> */}
          <Row className="flex w-full justify-center mt-5 ">
            <Col>
              <div className="flex flex-col w-full items-center justify-center  mb-5">
                <span
                  className="p-2 rounded-md flex w-fit items-center px-3"
                  style={{
                    backgroundColor: spotActive ? '#D0FFB4' : '#FFE5B4',
                    color: spotActive ? '#007A29' : '#B47700',
                    // borderRadius: "5px",
                    // border: "black solid 2px",
                  }}
                >
                  {spotActive ? 'Active' : 'Inactive'}
                  <span
                    className=" ml-2 w-2 h-2 rounded-full "
                    style={{
                      backgroundColor: spotActive ? '#007A29' : '#B47700',
                    }}
                  ></span>
                </span>
              </div>

              <div className="font-montserrat">
                <InputGroup className="flex w-full flex-col justify-center items-center">
                  <Row className="flex w-full flex-col justify-center items-center">
                    <Col>
                      <Label className="text-lg">
                        Investment amount ( in USD )
                      </Label>
                    </Col>
                    <Col className="flex w-full justify-center">
                      <Input
                        value={spotDollarAmount}
                        readOnly={spotActive}
                        onChange={(e) => setSpotDollarAmount(e.target.value)}
                        type="number"
                        className="w-[30%] mb-5 bg-gray-100"
                      />
                    </Col>
                    <Row className="flex justify-center w-full">
                      <Button
                        color="primary"
                        disabled={loading}
                        className="w-fit rounded-lg mt-5"
                        style={{
                          backgroundColor: spotActive ? 'white' : 'black',
                          color: spotActive ? 'black' : 'white',

                          padding: '10px',
                        }}
                        onClick={() => {
                          if (spotActive) {
                            //stop
                            stopBot();
                          } else {
                            //start
                            startBot();
                          }
                        }}
                      >
                        <div className="flex items-center">
                          {loading ? (
                            <Spinner size={'sm'} />
                          ) : spotActive ? (
                            'DELETE BOT'
                          ) : (
                            'START BOT'
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="ml-2"
                            width="25"
                            height="25"
                            viewBox="0 0 48 48"
                            fill="none"
                          >
                            <path
                              d="M24 30C27.3137 30 30 27.3137 30 24C30 20.6863 27.3137 18 24 18C20.6863 18 18 20.6863 18 24C18 27.3137 20.6863 30 24 30Z"
                              fill={spotActive ? 'black' : 'white'}
                            />
                            <path
                              d="M13.5 23.9999C13.5 20.5405 15.2393 17.5117 17.8419 15.8405L15.6725 13.5425C15.0769 13.9813 14.5221 14.4727 14.0146 15.0109C11.7482 17.412 10.5 20.6044 10.5 23.9999C10.5 27.3954 11.7482 30.5878 14.0147 32.9889C14.5222 33.5271 15.077 34.0185 15.6726 34.4572L17.842 32.1593C15.2393 30.4882 13.5 27.4593 13.5 23.9999ZM34.5 23.9999C34.5 27.4593 32.7607 30.4882 30.1581 32.1593L32.3275 34.4572C32.9231 34.0185 33.4779 33.5271 33.9854 32.9889C36.2518 30.5878 37.5 27.3954 37.5 23.9999C37.5 20.6044 36.2518 17.412 33.9853 15.011C33.4778 14.4728 32.923 13.9814 32.3274 13.5426L30.158 15.8406C32.7607 17.5117 34.5 20.5405 34.5 23.9999Z"
                              fill={spotActive ? 'black' : 'white'}
                            />
                            <path
                              d="M6 24C6 18.7896 8.36728 14.165 12.0247 11.2672L9.88434 9C9.31722 9.47301 8.77885 9.97947 8.27213 10.5167C6.62006 12.2669 5.32256 14.3058 4.41581 16.5768C3.47634 18.9297 3 21.4271 3 24C3 26.5728 3.47634 29.0704 4.41581 31.4232C5.32256 33.6944 6.62006 35.7332 8.27213 37.4834C8.7789 38.0206 9.31726 38.527 9.88434 39.0001L12.0246 36.7329C8.36728 33.835 6 29.2104 6 24ZM42 24C42 29.2104 39.6327 33.835 35.9754 36.7329L38.1156 38.9686C38.6781 38.4998 39.2162 38.0253 39.7279 37.4833C41.3799 35.7331 42.6774 33.6943 43.5842 31.4231C44.5236 29.0704 45 26.5728 45 24C45 21.4271 44.5236 18.9297 43.5842 16.5768C42.6774 14.3057 41.3799 12.2669 39.7279 10.5167C39.2212 9.97943 38.6828 9.47296 38.1156 9L35.9753 11.2672C39.6327 14.165 42 18.7896 42 24Z"
                              fill={spotActive ? 'black' : 'white'}
                            />
                          </svg>
                        </div>
                      </Button>
                    </Row>
                  </Row>
                </InputGroup>

                {/* <Row className="justify-content-right full-width">
                  <Button
                    color="primary"
                    disabled={loading}
                    style={{
                      maxWidth: "130px",
                      backgroundColor: spotActive ? "white" : "black",
                      color: spotActive ? "black" : "white",
                      borderRadius: "0px",
                      border: "black solid 2px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      if (spotActive) {
                        //stop the bot
                        setSpotActive(false);
                        stopBot();
                      } else {
                        // start the bot
                        setSpotActive(true);
                        startBot();
                        // send api call to start the bot.
                      }
                    }}
                  >
                    {loading ? (
                      <Spinner size={"sm"} />
                    ) : spotActive ? (
                      "DELETE BOT"
                    ) : (
                      "START BOT"
                    )}
                  </Button>
                </Row>
                <Row className="justify-content-right full-width mt-2">
                  <Col xs="12">
                    Status
                    <span
                      style={{
                        backgroundColor: spotActive ? "white" : "black",
                        color: spotActive ? "black" : "white",
                        borderRadius: "5px",
                        border: "black solid 2px",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {spotActive ? "ACTIVE" : "INACTIVE"}
                    </span>
                  </Col>
                </Row> */}
              </div>
            </Col>
          </Row>
          {/* <Row className="d-flex full-viewport-width justify-content-center mt-5">
            <Col xs="12" md="8">
              <Panel>
                <Row>
                  <Col xs="12">
                    Status
                    <span
                      style={{
                        backgroundColor: spotActive ? "white" : "black",
                        color: spotActive ? "black" : "white",
                        // borderRadius: "5px",
                        border: "black solid 2px",
                        padding: "10px",
                        marginLeft: "20px",
                      }}
                    >
                      {spotActive ? "ACTIVE" : "INACTIVE"}
                    </span>
                  </Col>
                </Row>
              </Panel>
            </Col>
          </Row> */}
        </Col>
      </Row>
    </>
  );
};

export default SpotBot;
